import '@fontsource/rubik'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

Vuetify.config.silent = true

const opts = {
  theme: { dark: false },
  iconfont: 'mdi'
}

export default new Vuetify(opts)
