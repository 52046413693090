import axios from 'axios'
import paths from '../../../paths'

const methods = {}

methods.generate = async (data) => {
  return axios.post(paths.tasks.lpg.create, data)
}

methods.saveConfig = async ({ dir, seoTitle, seoDesc, seoImg, status }) => {
  return axios.patch(paths.tasks.lpg.saveConfig, { dir, seoTitle, seoDesc, seoImg, status })
}

methods.updateTask = () => {}
methods.removeTask = () => {}

export default methods
