import proxiesService from '@/services/api/proxies.service'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'proxies',
  state: getDefaultState(),
  getters: {},
  actions: {
    async getProxiesList() {
      try {
        const response = await proxiesService.getProxiesList()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key, i) => {
            const proxy = response.data[key]
            data.push({ text: `${i + 1}) ${proxy.name} | ${proxy.host} | ${proxy.country}`, value: proxy.id })
          })
          return data
        }
      } catch (e) {
        // e
      }
    },
    /**
     * Get Proxy Credentials
     * @param {Function} dispatch
     * @param {String} id
     * @returns {Promise<*>}
     */
    async getProxyCredentials({ dispatch }, { id }) {
      try {
        const response = await proxiesService.getProxyCredentials(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
    },
    /**
     * Check Proxy Status
     * @param {Function} dispatch
     * @param {Function} commit
     * @param {Object} item
     * @returns {Promise<*>}
     */
    async checkProxyStatus({ dispatch, commit }, id) {
      try {
        const response = await proxiesService.checkProxyStatus(id)
        if (response.status === 200) {
          await commit('__proxies/saveItem', response.data, { root: true })
          return response.data
        }
      } catch (e) {
        // e
      }
    },
    /**
     * Check Proxy Status
     * @param {Function} dispatch
     * @param {Function} commit
     * @param {Object} item
     * @returns {Promise<*>}
     */
    async cloneProxy({ dispatch, commit }, id) {
      try {
        const response = await proxiesService.cloneProxy(id)
        if (response.status === 200) {
          await commit('__proxies/saveItem', response.data, { root: true })
          return response.data
        }
      } catch (e) {
        // e
      }
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
