import dashboardService from '@/services/api/dashboard.service'
import helper from '@/utils/helper'

const getDefaultState = (def = {}) => ({
  ...{
    generalCounters: {},
    missingDataCounters: {},
    requestsStats: {}
  },
  ...def
})

const getters = {
  generalCounters: (state) => state.generalCounters,
  missingDataCounters: (state) => state.missingDataCounters,
  requestsStats: (state) => state.requestsStats
}

let getRequestsStatsTimeout

const actions = {
  /**
   * Get general counters for dashboard
   * @param {Function} commit
   * @returns {Promise<void>}
   */
  async getGeneralCounters({ commit }) {
    try {
      const response = await dashboardService.getGeneralCounters()
      if (response.status === 200) {
        await commit('SET_DASHBOARD_GENERAL_COUNTERS', response.data)
      }
    } catch (e) {
      console.log(e)
    }
  },
  /**
   * Get domains counter with missing data
   * @param {Function} commit
   * @returns {Promise<never>}
   */
  async getMissingDataCounters({ commit }) {
    try {
      const response = await dashboardService.getMissingDataCounters()
      if (response.status === 200) {
        await commit('SET_DASHBOARD_MISSING_DATA_COUNTERS', response.data)
      }
    } catch (e) {
      console.log(e)
    }
  },
  /**
   * Get domain's counter with missing data
   * @param {Function} commit
   * @returns {Promise<never>}
   */
  async getRequestsStats({ commit }) {
    clearTimeout(getRequestsStatsTimeout)
    return new Promise((resolve, reject) => {
      getRequestsStatsTimeout = setTimeout(async () => {
        const response = await dashboardService.getRequestsStats()
        if (response.status === 200) {
          await commit('SET_DASHBOARD_REQUESTS_STATS', response.data)
          resolve(response.data)
          return response.data
        }
      }, 200)
    })
  }
}

const mutations = {
  /**
   * Mutation for setting general counters data
   * @param {Object} state
   * @param {Object} data
   */
  SET_DASHBOARD_GENERAL_COUNTERS(state, data) {
    state.generalCounters = data
  },
  /**
   * Mutation for setting domains counter with missing data
   * @param {Object} state
   * @param {Object} data
   */
  SET_DASHBOARD_MISSING_DATA_COUNTERS(state, data) {
    state.missingDataCounters = data
  },
  /**
   * Mutation for setting requests stats
   * @param {Object} state
   * @param {Object} data
   */
  SET_DASHBOARD_REQUESTS_STATS(state, data) {
    state.requestsStats = data
  },
  /**
   * Mutation for setting requests stats
   * @param {Object} state
   * @param {Object} data
   */
  UPDATE_DASHBOARD_REQUESTS_STATS(state, data) {
    state.requestsStats = helper.deepMergeObject(state.requestsStats, data)
  },
  /**
   * Reset state
   * @param state
   */
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  name: 'dashboard',
  state: getDefaultState(),
  getters,
  actions,
  mutations
}
