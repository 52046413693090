// import store from '@/store'
// import Vue from 'vue'
// import Store from '../'
import domainsProvidersService from '@/services/api/domains/providers.service'

const getDefaultState = (def = {}) => ({
  ...{
    domainsProvidersList: {}
  },
  ...def
})

/**
 * Update resource table data
 * @param {Object} data
 */
const updateRecordInResourceTable = (data) => {
  // Vue.prototype.$refreshData.updateRecordInResourceTable('domains.providers', data)
  // Vue.prototype.$refreshData.leftSidebarDomainsCounters()
  // Vue.prototype.$refreshData.reloadResourceTable('domains.providers')
  //
  // Store.commit('domainsProviders/setDomainsProvidersList', data, { root: true })
}

export default {
  namespaced: true,
  name: 'domainsProviders',
  state: {
    domainsProvidersList: {}
  },
  getters: {
    getDomainsProvidersList: (state) => state.domainsProvidersList
  },
  actions: {
    async getDomainsProvidersList () {
      try {
        const response = await domainsProvidersService.getDomainsProvidersList()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const provider = response.data[key]
            data.push({ text: provider.name, value: provider.id })
          })
          return data
        }
      } catch (e) {
        // e
      }
    },
    async getDomainsProviderCredentials ({ dispatch }, { id }) {
      try {
        const response = await domainsProvidersService.getDomainsProviderCredentials(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
    },
    async getDefaultNss ({ dispatch }, { id }) {
      try {
        const response = await domainsProvidersService.getDefaultNss(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
    },
    /* --------------------------------------------- */
    // DOMAINS
    /* --------------------------------------------- */
    async importDomains({ dispatch, commit }, { id }) {
      await domainsProvidersService.importDomains(id)
    },
    async domainSync({ dispatch, commit }, { id, domainId, name }) {
      const response = await domainsProvidersService.domainSync(id, name)
      return response.data
    },
    async domainAdd({ dispatch, commit }, { data }) {
      const response = await domainsProvidersService.domainAdd(data.domain_provider_id, data.name)
      return response.data
    },
    async nssSet({ dispatch, commit }, { id, domain, nss }) {
      const response = await domainsProvidersService.nssSet(id, domain, nss)
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    async dnsSet({ dispatch, commit }, { id, domain, subdomain, content, type }) {
      const response = await domainsProvidersService.dnsSet(id, domain, subdomain, content, type)
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    async dnsRemove({ dispatch, commit }, { id, domain, subdomain, content, type }) {
      const response = await domainsProvidersService.dnsRemove(id, domain, subdomain, content, type)
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    }
  },
  mutations: {
    setDomainsProvidersList(state, data) {
      state.domainsProvidersList = data
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
