export default (i18n, counts) => [
  {
    path: '/admin/dashboard',
    name: 'dashboard',
    title: i18n.t('leftSidebar.dashboard'),
    icon: 'view-dashboard-outline',
    permission: 'dashboard.view'
  },
  // {
  //   path: '/admin/tags',
  //   name: 'tags',
  //   title: i18n.t('leftSidebar.tags'),
  //   icon: 'tag-multiple',
  //   permission: 'tags.list'
  // },
  {
    path: '/admin/affiliates',
    name: 'affiliates',
    title: i18n.t('leftSidebar.affiliates.affiliates'),
    icon: 'handshake-outline',
    permission: 'affiliates.management.list',
    children: [
      {
        path: '/admin/affiliates/teams',
        name: 'affiliates:teams',
        title: i18n.t('leftSidebar.affiliates.teams'),
        icon: 'account-group-outline',
        permission: 'affiliates.teams.list'
      },
      {
        path: '/admin/affiliates/management',
        name: 'affiliates:management',
        title: i18n.t('leftSidebar.affiliates.management'),
        icon: 'account-multiple',
        permission: 'affiliates.management.list'
      }
    ]
  },
  // {
  //   path: '/admin/events',
  //   name: 'events',
  //   title: i18n.t('leftSidebar.events'),
  //   icon: 'calendar-star',
  //   permission: 'events.list'
  // },
  {
    path: '/admin/users',
    name: 'users',
    title: i18n.t('leftSidebar.users.users'),
    icon: 'account-multiple-outline',
    permission: 'users.management.list',
    children: [
      {
        path: '/admin/users/management',
        name: 'users:management',
        title: i18n.t('leftSidebar.users.management'),
        icon: 'account-multiple',
        permission: 'users.management.list'
      },
      {
        path: '/admin/users/permissions',
        name: 'users:permissions',
        title: i18n.t('leftSidebar.users.permissions'),
        icon: 'folder-account-outline',
        permission: 'users.permissions.view'
      },
      {
        divider: true
      }
    ]
  },
  {
    path: '/admin/hosting',
    name: 'hosting',
    title: i18n.t('leftSidebar.hosting.hosting'),
    icon: 'server',
    children: [
      {
        path: '/admin/hosting/providers',
        name: 'hosting:providers',
        title: i18n.t('leftSidebar.hosting.providers'),
        icon: 'server-security',
        permission: 'hosting.providers.list'
      },
      {
        path: '/admin/hosting/servers',
        name: 'hosting:providers',
        title: i18n.t('leftSidebar.hosting.servers'),
        icon: 'server-network',
        permission: 'hosting.servers.list'
      },
      {
        divider: true
      }
    ]
  },
  {
    path: '/admin/domains',
    name: 'domains',
    title: i18n.t('leftSidebar.domains.domains'),
    icon: 'web',
    children: [
      {
        path: '/admin/domains/providers',
        name: 'domains:providers',
        title: i18n.t('leftSidebar.domains.providers.providers'),
        icon: 'webpack',
        // permission: 'domains.providers.list',
        children: [
          {
            path: '/admin/domains/providers/accounts',
            name: 'domains:providers',
            title: i18n.t('leftSidebar.domains.providers.providerAccounts'),
            icon: 'account-details-outline'
          },
          {
            path: '/admin/tasks/sdr',
            name: 'tasks:sdr',
            title: i18n.t('leftSidebar.tasks.sdr'),
            icon: 'timetable',
            permission: 'tasks.sdr.list'
          },
          {
            divider: true
          }
        ]
      },
      {
        path: '/admin/domains/cloudflare',
        name: 'domains:cloudflare',
        title: i18n.t('leftSidebar.domains.cloudflare'),
        icon: 'cloud-outline',
        permission: 'domains.cloudflare.list'
      },
      {
        path: '/admin/domains',
        name: 'domains',
        title: i18n.t('leftSidebar.domains.management.management'),
        icon: 'home-city-outline',
        children: [
          {
            path: '/admin/domains/management/active',
            name: 'domains:active',
            count: counts.active || 0,
            title: i18n.t('leftSidebar.domains.management.active'),
            icon: 'check',
            permission: 'domains.management.list'
          },
          {
            path: '/admin/domains/management/pending-registration',
            name: 'domains:pendingRegistration',
            count: counts.pendingRegistration || 0,
            title: i18n.t('leftSidebar.domains.management.pendingRegistration'),
            icon: 'timer-sand',
            permission: 'domains.management.list'
          },
          {
            path: '/admin/domains/management/pending-settings',
            name: 'domains:pendingSettings',
            count: counts.pendingSettings || 0,
            title: i18n.t('leftSidebar.domains.management.pendingSettings'),
            icon: 'wrench-clock',
            permission: 'domains.management.list'
          },
          {
            path: '/admin/domains/management/draft',
            name: 'domains:draft',
            count: counts.draft || 0,
            title: i18n.t('leftSidebar.domains.management.draft'),
            icon: 'file',
            permission: 'domains.management.list'
          },
          {
            path: '/admin/domains/management/with-errors',
            name: 'domains:withErrors',
            count: counts.withErrors || 0,
            title: i18n.t('leftSidebar.domains.management.withErrors'),
            icon: 'bug-outline',
            permission: 'domains.management.list'
          },
          // {
          //   path: '/admin/domains/management/locked',
          //   name: 'domains:locked',
          //   count: counts.locked || 0,
          //   title: i18n.t('leftSidebar.domains.management.locked'),
          //   icon: 'lock-check-outline',
          //   permission: 'domains.management.list'
          // },
          {
            path: '/admin/domains/management/deleted',
            name: 'domains:deleted',
            title: i18n.t('leftSidebar.domains.management.deleted'),
            icon: 'trash-can-outline',
            count: counts.deleted || 0,
            permission: 'domains.management.list'
          },
          {
            path: '/admin/domains/management/all',
            name: 'domains:all',
            count: counts.all || 0,
            title: i18n.t('leftSidebar.domains.management.all'),
            icon: 'image-filter-hdr',
            permission: 'domains.management.list'
          },
          {
            divider: true
          }
        ]
      }
      // {
      //   path: '/admin/tasks/generator',
      //   name: 'tasks:generator',
      //   title: i18n.t('leftSidebar.tasks.generator'),
      //   icon: 'spider-web',
      //   permission: 'tasks.generator.list'
      // },
      // {
      //   divider: true
      // }
    ]
  },
  {
    path: '/admin/settings',
    name: 'settings',
    title: i18n.t('leftSidebar.settings'),
    icon: 'cog-outline',
    permission: 'settings.view'
  },
  {
    path: '/admin/services',
    name: 'emails',
    title: i18n.t('leftSidebar.services'),
    icon: 'gauge',
    permission: 'services.list'
  },
  {
    path: '/admin/emails',
    name: 'emails',
    title: i18n.t('leftSidebar.emails'),
    icon: 'email-fast-outline',
    permission: 'emails.list'
  },
  {
    path: '/admin/proxies',
    name: 'proxies',
    title: i18n.t('leftSidebar.proxies'),
    icon: 'guy-fawkes-mask',
    permission: 'proxies.list'
  },
  {
    path: '/admin/tasks',
    name: 'bundle',
    title: i18n.t('leftSidebar.tasks.tasks'),
    icon: 'calendar-check-outline',
    children: [
      {
        path: '/admin/tasks/archive-upload',
        name: 'tasks:upload',
        title: i18n.t('leftSidebar.tasks.upload'),
        icon: 'archive-arrow-up-outline',
        permission: 'tasks.upload.list'
      },
      {
        path: '/admin/tasks/generator',
        name: 'tasks:generator',
        title: i18n.t('leftSidebar.tasks.generator'),
        icon: 'spider-web',
        permission: 'tasks.generator.list'
      },
      // {
      //   path: '/admin/tasks/white-pages-generator',
      //   name: 'tasks:wpg',
      //   title: i18n.t('leftSidebar.tasks.wpg'),
      //   icon: 'air-filter',
      //   permission: 'tasks.wpg.list'
      // },
      // {
      //   path: '/admin/tasks/cloning',
      //   name: 'tasks:cloning',
      //   title: i18n.t('leftSidebar.tasks.cloning'),
      //   icon: 'content-copy',
      //   permission: 'tasks.cloning.list'
      // },
      {
        path: '/admin/tasks/landing-pages-generator',
        name: 'tasks:lpg',
        title: i18n.t('leftSidebar.tasks.lpg'),
        icon: 'arrange-bring-to-front',
        permission: 'tasks.lpg.list'
      },
      {
        divider: true
      }
    ]
  },
  // {
  //   path: '/admin/docs',
  //   name: 'docs',
  //   title: i18n.t('Documents'),
  //   icon: 'text-box-multiple'
  // },
  {
    path: '/auth/logout',
    name: 'logout',
    title: i18n.t('leftSidebar.logout'),
    icon: 'exit-run',
    class: 'logout-link'
  }
]
