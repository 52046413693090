// import Vue from 'vue'
// import store from '@/store'
import tasksGeneratorService from '@/services/api/tasks/generator.service.js'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'tasksGenerator',
  state: getDefaultState(),
  getters: {

  },
  actions: {
    /**
     * Restart job in task
     * @param dispatch
     * @param id
     * @param inputData
     * @returns {Promise<*>}
     */
    async restartJobInTask({ dispatch }, { id, inputData }) {
      const response = await tasksGeneratorService.restartJobInTask(id, inputData)
      const { status, data } = response
      if (status === 200) {
        return data
      }
      return response
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
