<template>
  <div style="width: 100px">
    <code>{{ `${Math.round(retries - 1) > 0 ? `${retries - 1} x` : ''}` }} {{ days > 0 ? fillZero(days) : '' }} {{ fillZero(hours) }}:{{ fillZero(minutes) }}:{{ fillZero(seconds) }}</code>
  </div>
</template>

<script>
export default {
  name: 'TimeAgo',
  props: {
    date: {
      type: [Date, String],
      required: true
    },
    retries: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      millisecond: 0,
      intervals: {
        millisecond: 1,
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateDiffs()
    }, 1000)

    this.updateDiffs()
  },
  /**
   *
   */
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    updateDiffs() {
      this.inputDate = new Date().getTime() - new Date(this.date)
      this.days = Math.trunc(this.inputDate / this.intervals.day)
      this.hours = Math.trunc((this.inputDate % this.intervals.day) / this.intervals.hour)
      this.minutes = Math.trunc((this.inputDate % this.intervals.hour) / this.intervals.minute)
      this.seconds = Math.trunc((this.inputDate % this.intervals.minute) / this.intervals.second)
      this.millisecond = Math.trunc((this.inputDate % this.intervals.second) / this.intervals.millisecond)
    },
    fillZero(num) {
      if (num < 0) {
        num = 0
      }
      return num < 10 ? '0' + num : num
    },
    fillMillisecond(n) {
      return ('0000' + n).slice(-4)
    }
  }
}
</script>

<style scoped>

</style>
