import axios from 'axios'
import paths from '../../paths'

const methods = {}

/**
 * Get credentials record for any entity by id
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getCredentials = async (id) => {
  return axios.get(paths.credentials.view.replace(':id', id))
}

/**
 * Save credentials record by id
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.saveCredentials = async (data) => {
  return axios.post(paths.credentials.save.replace(':id', data.id), data)
}

/**
 * Remove credentials record for any entity by id
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.deleteCredentials = async (id) => {
  return axios.delete(paths.credentials.remove.replace(':id', id))
}

export default methods
