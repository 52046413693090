import axios from 'axios'
import paths from '../../../paths'

const methods = {}

methods.commonUpload = async (file, onUploadProgress) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(paths.tasks.upload.upload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

export default methods
