import axios from 'axios'
import paths from '../../paths'

/**
 * Login
 * @param {Object} payload
 * @returns {AxiosPromise<any>}
 */
const login = async (payload) => {
  return axios.post(paths.auth.login, payload)
}

/**
 * Refresh tokens
 * @param payload
 * @returns {AxiosPromise<any>}
 */
const refreshTokens = async (payload) => {
  if (!('refreshToken' in payload) || !payload.refreshToken) {
    return false
  }
  return axios.post(paths.auth.refreshTokens, { refreshToken: payload.refreshToken })
}

/**
 * Refresh tokens
 * @param payload
 * @returns {AxiosPromise<any>}
 */
const refreshAccessToken = async (payload) => {
  if (!('refreshToken' in payload) || !payload.refreshToken) {
    return false
  }
  return axios.post(paths.auth.refreshAccessToken, { refreshToken: payload.refreshToken })
}

/**
 * Refresh tokens
 * @returns {AxiosPromise<any>}
 * @param token
 */
const loginWithOneTimeToken = async (token) => {
  return axios.post(paths.auth.loginWithOneTimeToken, { token })
}

/**
 * Get data of authed user
 * @returns {AxiosPromise<any>}
 */
const getCurrentUser = async () => {
  return axios.get(paths.users.getCurrentUser)
}

/**
 * Log out
 * @param {Object} payload
 * @returns {AxiosPromise<any>}
 */
const logout = async (payload) => {
  if (!('refreshToken' in payload) || !payload.refreshToken) {
    return false
  }
  return axios.post(paths.auth.logout, { refreshToken: payload.refreshToken })
}

export default {
  login,
  loginWithOneTimeToken,
  refreshTokens,
  refreshAccessToken,
  getCurrentUser,
  logout
}
