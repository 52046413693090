<template>
  <div>
    <v-dialog
      ref="dialogTools"
      v-model="dialogEventLog"
      scrollable
      :max-width="`800px`"
      :hide-overlay="true"
      :persistent="true"
      :no-click-animation="true"
      :retain-focus="false"
      :fullscreen="fullscreen"
      class="dialogEventLogContainer"
      transition="fab-transition"
    >
      <v-card
        :max-height="`300px`"
        :class="`dialog-container dialog-transparent`"
      >
        <v-card-title>
          <span class="text-h5">Event log</span>
          <v-spacer />
          <v-text-field
            v-show="!!showFilterField"
            ref="eventLogFilter"
            v-model="filter"
            :value="filter"
            append-icon="mdi-filter-outline"
            placeholder="Filter"
            single-line
            hide-details
            class="event-log-filter-field ml-5"
            @blur="showFilterField = !!filter && !!showFilterField"
          />
          <v-btn
            v-show="!showFilterField"
            icon
            class="ml-5"
            @click="showFilterForm"
          >
            <v-icon>mdi-text-search</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text ref="logContainer">
          <v-virtual-scroll
            :bench="0"
            :items="eventLog"
            :height="containerHeight"
            item-height="40"
          >
            <template v-slot:default="{ item }">
              <pre
                :key="item.message"
                style="overflow: hidden"
              >
[{{ $helper.dateTimeFilter(item.date, true) }}] <!-- eslint-disable --><span
:style="`color: ${item.type === 'message' ? '#558B2F' : '#D84315'}`"
v-html="parseLinkInMessage(item.message)"
/><!-- eslint-enable -->
<v-divider class="mt-2" />
</pre>
            </template>
          </v-virtual-scroll>
          <!--div style="overflow: hidden">
            <pre
              v-for="(log, key) in eventLog"
              :key="key"
            >
[{{ $helper.dateTimeFilter(log.date) }}] <span :style="`color: ${log.type === 'message' ? '#558B2F' : '#D84315'}`">{{ log.message }}</span>
</pre>
          </div-->
        </v-card-text>
        <v-card-actions>
          <v-col class="pa-0 checkbox-text" />
          <v-col class="text-right pb-0">
            <v-btn
              color="blue darken-1"
              text
              class="align-content-end close-btn"
              @click="fullscreen = !fullscreen"
            >
              Full-screen
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              class="align-content-end close-btn"
              @click="close()"
            >
              {{ $t('dialog.close') }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogEventLog',
  props: {
    dialogEventLog: Boolean
  },
  data () {
    return {
      fullscreen: false,
      containerHeight: 170,
      showFilterField: false,
      filter: ''
    }
  },
  computed: {
    eventLog () {
      const items = this.$store.getters['eventLog/getEventLog']
      return Object.keys(items)
        .map(key => items[key])
        .filter(item => item.message.indexOf(this.filter) > -1)
    }
  },
  watch: {
    eventLog(val) {},
    /**
     * Fix scroll
     * @see https://github.com/vuetifyjs/vuetify/issues/6662
     */
    dialogEventLog (val) {
      if (val === true && this.fullscreen === false) {
        this.$nextTick(() => {
          this.$refs.dialogTools.showScroll()
        })
      }
    },
    fullscreen(val) {
      this.$nextTick(() => {
        this.setContainerHeight()
      })
    }
  },
  async mounted () {
    await this.$store.dispatch('eventLog/getUserEventLog')
    if (typeof window === 'undefined') {
      return false
    }
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window === 'undefined') {
      return false
    }
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    close () {
      this.$store.dispatch('adminLayout/toggleDialogEventLog')
    },
    focusSearchInput() {
      this.$refs.eventLogFilter.focus()
    },
    showFilterForm() {
      this.showFilterField = !this.showFilterField
      this.$nextTick(() => {
        this.focusSearchInput()
      })
    },
    setContainerHeight() {
      if (typeof this.$refs.logContainer === 'undefined') {
        return false
      }
      this.containerHeight = this.$refs.logContainer.clientHeight
    },
    onResize() {
      this.setContainerHeight()
    },
    parseLinkInMessage(str) {
      const pattern = /\[link=([^\s\]]+)\s*\](.*(?=\[\/link\]))\[\/link\]/
      return str.replace(pattern, '<a href="$1">$2</a>')
    }
  }
}
</script>

<style lang="scss">
.dialog-container .v-card__text {
  padding: 0 24px 0px !important;
}
.dialog-container {
  .close-btn {
    margin-top: -15px;
  }
}
.event-log-filter-field {
 /* max-width: 200px; */
  font-weight: normal;
  margin-top: -10px;
}
.dialog-transparent {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";

  /* IE 5-7 */
  filter: alpha(opacity=70);

  /* Netscape */
  -moz-opacity: 0.75;

  /* Safari 1.x */
  -khtml-opacity: 0.75;

  /* Good browsers */
  opacity: 0.75;
}
.dialog-transparent:hover {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  /* IE 5-7 */
  filter: alpha(opacity=100);

  /* Netscape */
  -moz-opacity: 1;

  /* Safari 1.x */
  -khtml-opacity: 1;

  /* Good browsers */
  opacity: 1;
}

</style>
