<template>
  <v-list-item
    v-if="!item.children && item.divider !== true"
    v-can="item.permission"
    :to="item.path"
    :class="`${item.class !== undefined ? ` ${item.class}` : ``}`"
  >
    <v-list-item-icon>
      <v-icon>{{ `mdi-${item.icon}` }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>
      <span>{{ item.title }}</span>
      <span
        v-if="Number.isInteger(item.count)"
        class="ml-3 grey--text small"
      >({{ item.count }})</span>
    </v-list-item-title>
  </v-list-item>
  <v-divider v-else-if="item.divider === true" />
  <v-list-group
    v-else
    v-can="item.permission"
    :value="isMenuActive(item)"
    no-action
    :sub-group="subGroup"
  >
    <template #activator>
      <v-list-item-icon>
        <v-icon>{{ `mdi-${item.icon}` }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>
    <left-sidebar-item
      v-for="(subItem, i) in item.children"
      :key="i"
      v-can="item.permission"
      :item="subItem"
      :sub-group="true"
    />
  </v-list-group>
</template>

<script>
export default {
  name: 'LeftSidebarItem',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    subGroup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMenuActive() {
      return (item) => {
        const path = this.$route.fullPath.split('?')[0]
        let open = false
        const findActive = (item) => {
          if (item.children !== undefined) {
            item.children.forEach((v) => {
              if (v.path && (path === v.path)) {
                open = true
              } else if (v.children) {
                findActive(v)
              }
            })
          }
        }
        findActive(item)
        return open
      }
    }
  }
}
</script>

<style lang="scss">
.v-list-item__icon, .v-list-item__icon:first-child {
  margin-right: 15px !important;
}

.v-list-group__items .v-list-item {
  padding-left: 20px !important;
}

.v-list-group--sub-group {
  padding-left: 0;
}

.v-list-group--sub-group .v-list-item {
  padding-left: 60px !important;
}

.v-list-group--sub-group > .v-list-item {
  padding-left: 35px !important;
}

.v-list-group--sub-group > .v-list-group__items > .v-list-item {
  padding-left: 70px !important;
}

.logout-link:hover {
  background-color: #fff2f2;
}

.v-list-group__header__prepend-icon {
  position: absolute;
  right: 17px;
}

div.v-list-group.v-list-group--no-action.primary--text > div.v-list-group__items > div > div.v-list-group__items > .v-list-item.v-list-item--link.theme--light > div.v-list-item__title {
  font-size: 13px;
  color: rgb(78, 78, 78);
}

div.v-list-group.v-list-group--no-action.primary--text > div.v-list-group__items > div > div.v-list-group__items > .v-list-item.v-list-item--link.theme--light > i.v-icon {
  font-size: 18px !important;
}
</style>
