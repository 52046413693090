<template>
  <div class="d-inline-flex">
    <v-menu
      :absolute="absolute"
      :top="top"
      :bottom="bottom"
      :left="left"
      :right="right"
      :close-on-content-click="closeOnContentClick"
      :offset-x="isOffsetX"
      :offset-y="isOffsetY"
      :position-x="x"
      :position-y="y"
      :open-on-hover="isOpenOnHover"
      :transition="transition"
      :value="openMenu"
      :icon="icon"
      :disabled="typeof disabled === 'function' ? disabled(item) : false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-list-item
          v-if="isSubMenu"
          v-can="can"
          link
          v-bind="attrs"
          :disabled="typeof disabled === 'function' ? disabled(item) : !isHas(has)"
          v-on="on"
          @click="typeof fn === 'function' ? fn(item) : () => {}"
        >
          <v-icon
            small
            class="mr-3"
            v-text="`mdi-${icon || 'dots-grid'}`"
          />
          <span style="font-size: small">{{ name }}</span>
          <div class="flex-grow-1" /><v-icon>mdi-chevron-right</v-icon>
        </v-list-item>
        <v-btn
          v-else
          text
          class="text-btn"
          v-bind="attrs"
          :loading="loading"
          v-on="on"
          @click="openMenu = true"
        >
          <v-icon :small="smallButtons">
            mdi-{{ icon || 'dots-grid' }}
          </v-icon>
        </v-btn>
      </template>

      <v-list
        class="drop-menu-container"
      >
        <v-list-item-group>
          <template
            v-for="(action, i) in actionsList"
          >
            <v-divider
              v-if="action.divider === true"
              :key="i"
            />
            <drop-menu
              v-else-if="action.children"
              :key="i"
              :actions-list="action.children"
              :name="action.text"
              :icon="action.icon"
              :has="action.has"
              :can="action.can"
              :fn="action.fn"
              :disabled="action.disabled"
              :is-sub-menu="true"
              :item="item"
              :custom-text="action.customText"
              @drop-menu-click="emitClickEvent"
            />
            <v-list-item
              v-else
              v-show="isHas(action.has)"
              :key="i"
              v-can="action.can"
              :disabled="typeof action.disabled === 'function' ? action.disabled(item) : action.disabled || false"
              link
              @click="typeof action.fn === 'function' ? (action.fn(item) & emitClickEvent(action)) : () => {}"
            >
              <v-icon
                :small="action.smallButtons || smallButtons"
                class="mr-3"
                v-text="`mdi-${action.icon}`"
              />
              <span class="menu-item-text">{{ typeof action.customText === 'function' ? action.customText(item) : action.text }}</span>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: true
    },
    closeOnContentClick: {
      type: Boolean,
      default: false
    },
    isOpenOnHover: {
      type: Boolean,
      default: false
    },
    isOffsetX: {
      type: Boolean,
      default: false
    },
    isOffsetY: {
      type: Boolean,
      default: true
    },
    transition: {
      type: String,
      default: 'slide-y-transition'
    },
    isSubMenu: {
      type: Boolean,
      default: false
    },
    has: {
      type: String,
      default: undefined
    },
    can: {
      type: String,
      default: undefined
    },
    actionsList: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    fn: {
      type: Function,
      default: () => {
      }
    },
    disabled: {
      type: Function,
      default: () => {
      }
    },
    loading: {
      type: Boolean,
      default: false
      // type: Function,
      // default: () => {
      // }
    },
    smallButtons: {
      type: Boolean,
      default: true
    },
    customText: {
      type: Function,
      default: () => {
      }
    }
  },
  data: () => {
    return {
      x: 0,
      y: 0,
      openMenu: false
    }
  },
  methods: {
    isHas(propName) {
      if (typeof propName === 'string') {
        return Object.prototype.hasOwnProperty.call(this.item, propName) && this.item[propName] !== null
      }
      return true
    },
    setPosition(e) {
      this.x = e.clientX
      this.y = e.clientY
    },
    emitClickEvent (act) {
      this.$emit('drop-menu-click', act)
      this.openMenu = false
    }
  }
}
</script>

<style lang="scss">
.drop-menu-container {
  .v-list-item {
    min-height: 20px !important;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: right;
  }
  width: auto;
  max-width: 200px;
  .menu-item-text {
    font-size: small
  }
}
.text-btn {
  height: 30px !important;
  min-width: 30px !important;
  padding: 0 7px !important;
  color: #0000008a !important;
}
</style>
