import credentialsService from '@/services/api/credentials.service'

const getDefaultState = (def = {}) => ({
  ...{
    credentials: []
  },
  ...def
})

export default {
  namespaced: true,
  name: 'credentials',
  state: getDefaultState(),
  getters: {
    credentials: state => state.credentials
  },
  actions: {
    /**
     * Get Email Credentials
     * @param {Function} dispatch
     * @param {String} id
     * @returns {Promise<*>}
     */
    async getCredentials({ commit }, entityId) {
      const response = await credentialsService.getCredentials(entityId)
      const { status, data } = response
      if (status === 200) {
        commit('credentials', data)
      }
      return data
    },
    async saveCredentials({ state, commit }, inputData) {
      const response = await credentialsService.saveCredentials(inputData)
      const { status, data } = response
      if (status === 200) {
        return data
      }
      return data
    }
  },
  mutations: {
    credentials(state, credentials) {
      state.credentials = credentials
    },
    prependCredentials(state) {
      state.credentials.credentials.unshift({
        login: '',
        password: '',
        notes: ''
      })
    },
    removeCredentials(state, key) {
      state.credentials.credentials.splice(key, 1)
    },
    resetCredentials(state) {
      state.credentials = []
    },
    /**
     * Reset state
     * @param state
     */
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
