<template>
  <v-row
    v-if="dialog"
    justify="center"
  >
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      scrollable
      @keydown.esc="cancel"
    >
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer />
          <v-btn
            icon
            @click="cancel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-2" />
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row v-if="credentials.length < 1">
              <v-col cols="12">
                <v-alert
                  type="info"
                  color="green"
                  icon="mdi-information"
                  outlined
                  text
                >
                  {{ $t('This entity has no additional credentials') }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row
              v-for="(cred, key) in credentials"
              v-else
              :key="key"
            >
              <v-col cols="6">
                <v-text-field
                  v-model="cred.login"
                  :label="$t('Login')"
                  hide-details="auto"
                  prepend-icon="mdi-account-outline"
                  append-outer-icon="mdi-content-copy"
                  :rules="[
                    v => !!v || $t('Login is required')
                  ]"
                  validate-on-blur
                  @click:append-outer="$copyToClipboard(cred.login)"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="cred.password"
                  prepend-icon="mdi-form-textbox-password"
                  :append-icon="cred.showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  append-outer-icon="mdi-content-copy"
                  :type="cred.showPass ? 'text' : 'password'"
                  :label="$t('Password')"
                  hide-details="auto"
                  :rules="[
                    v => !!v || $t('Password is required')
                  ]"
                  validate-on-blur
                  @click:append="toggleShowPass(cred)"
                  @click:append-outer="$copyToClipboard(cred.password)"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="cred.notes"
                  hide-details="auto"
                  :auto-grow="true"
                  :rows="1"
                  :label="$t('Notes')"
                  outlined
                  prepend-inner-icon="mdi-comment-text-outline"
                  append-icon="mdi-content-copy"
                  :append-outer-icon="$can('credentials.remove') ? 'mdi-close-box-outline' : ''"
                  @click:append="$copyToClipboard(cred.notes)"
                  @click:append-outer="remove(key)"
                />
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            v-if="!options.noConfirm"
            :color="options.cancelBtn.color"
            text
            class="body-2 font-weight-bold"
            @click.native="cancel"
          >
            {{ options.cancelBtn.text }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-can="'credentials.save'"
            :color="options.addBtn.color"
            class="body-2 font-weight-bold"
            text
            @click.native="add"
          >
            {{ options.addBtn.text }}
          </v-btn>
          <v-btn
            v-can="'credentials.save'"
            :color="options.submitBtn.color"
            class="body-2 font-weight-bold"
            text
            @click.native="save"
          >
            {{ options.submitBtn.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'Credentials',
  data () {
    return {
      valid: true,
      dialog: false,
      resolve: null,
      reject: null,
      data: [],
      title: null,
      entityId: null,
      entityType: null,
      affiliateTeamId: null,
      options: {
        color: '',
        width: 600,
        maxHeight: 300,
        zIndex: 200,
        cancelBtn: {
          text: 'Close',
          color: 'grey'
        },
        addBtn: {
          text: 'Add',
          color: 'primary'
        },
        submitBtn: {
          text: 'Save',
          color: 'primary'
        }
      }
    }
  },
  computed: {
    credentials() {
      return this.$store.getters['credentials/credentials'].credentials || []
    }
  },
  watch: {
    credentials(val) {
      if (this.valid !== true) {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    open (title, data, options) {
      this.dialog = true
      this.title = title
      this.entityId = data.entityId
      this.entityType = data.entityType
      this.affiliateTeamId = data.affiliateTeamId
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    add () {
      this.$store.commit('credentials/prependCredentials')
    },
    remove (key) {
      this.$store.commit('credentials/removeCredentials', key)
    },
    save () {
      const v = this.$refs.form.validate()
      if (v) {
        this.$store.dispatch('credentials/saveCredentials', {
          id: this.entityId,
          entity_type: this.entityType,
          affiliate_team_id: this.affiliateTeamId,
          credentials: this.credentials
        }).then((res) => {
          this.$toast.success(this.$t('Credentials saved successfully'))
          this.cancel()
        }).catch((err) => {
          this.$toast.error(this.$t(err.response.data.message || 'Credentials saving failed'))
        })
      }
    },
    cancel () {
      this.$store.commit('credentials/resetCredentials')
      this.dialog = false
    },
    toggleShowPass(cred) {
      this.$set(cred, 'showPass', !cred.showPass)
      // cred.showPass = !cred.showPass
    }
  }
}
</script>

<style scoped>

</style>
