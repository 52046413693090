import axios from 'axios'
import paths from '../../paths'

const methods = {}

/**
 * Get user event log
 * @returns {AxiosPromise<any>}
 */
methods.getEventLog = async () => {
  return axios.get(paths.eventLog.list)
}

export default methods
