<template>
  <div v-if="['xl', 'lg', 'md'].includes($vuetify.breakpoint.name)">
    <v-badge
      v-for="(server, index) of socketServers"
      :key="`socketServers_${index}`"
      :value="
        hoverSocketBtn[index] === undefined ? false : hoverSocketBtn[index]
      "
      color="grey accent-4"
      :content="`+${server.clientsCount || 0}`"
      left
    >
      <v-hover v-model="hoverSocketBtn[index]">
        <div class="d-inline-flex">
          <v-icon
            :class="`mr-5 ${
              server.healthy !== true ? ' blink-me ' : ' flash-healthy '
            }`"
            :color="server.healthy === true ? ' lighten-1' : 'grey'"
            @click="
              () => {
                if (server.healthy !== true) {
                  $socket.connect()
                }
              }
            "
          >
            mdi-lightning-bolt-circle
          </v-icon>
        </div>
      </v-hover>
    </v-badge>
  </div>
</template>

<script>
export default {
  name: 'SocketServersStatus',
  data() {
    return {
      hoverSocketBtn: {}
    }
  },
  computed: {
    socketServers() {
      return this.$store.getters['socket/socketServers']
    },
    socketNum() {
      return Object.keys(this.socketServers).length
    }
  }
}
</script>

<style scoped lang='scss'>
.flash-healthy {
  color: rgb(181, 212, 243);
}
</style>
