import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Import affiliates
 * @returns {AxiosPromise<any>}
 */
methods.import = async () => {
  return axios.post(paths.affiliates.management.import)
}

/**
 * Getting full list of affiliates
 * @returns {AxiosPromise<any>}
 */
methods.all = async () => {
  return axios.get(paths.affiliates.management.all)
}

export default methods
