export default {
  computed: {
    $currentUser () {
      return this.$store.getters['auth/loggedInUser']
    },
    $accessToken () {
      return this.$store.getters['auth/accessToken']
    },
    $refreshToken () {
      return this.$store.getters['auth/refreshToken']
    }
  }
}
