export default {
  auth: {
    login: '/auth/login',
    loginWithOneTimeToken: '/auth/login-with-one-time-token',
    logout: '/auth/logout',
    refreshTokens: '/auth/refresh-tokens',
    refreshAccessToken: '/auth/refresh-access-token'
  },
  dashboard: {
    getGeneralCounters: '/dashboard/general-counters',
    getMissingDataCounters: '/dashboard/missing-data-counters',
    getRequestsStats: '/dashboard/requests-stats'
  },
  tags: {
    list: '/tags',
    create: '/tags',
    update: '/tags/:id',
    remove: '/tags/:id',
    bulk: {
      remove: 'tags'
    }
  },
  affiliates: {
    management: {
      list: '/affiliates',
      create: '/affiliates',
      update: '/affiliates/:id',
      remove: '/affiliates/:id',
      import: '/affiliates/import-affiliates',
      bulk: {
        remove: 'affiliates'
      },
      all: '/affiliates/all'
    },
    teams: {
      list: '/affiliates/teams',
      create: '/affiliates/teams',
      permissions: '/affiliates/teams/:id/permissions',
      update: '/affiliates/teams/:id',
      remove: '/affiliates/teams/:id',
      bulk: {
        remove: '/affiliates/teams'
      },
      all: '/affiliates/teams/all'
    }
  },
  users: {
    management: {
      list: '/users',
      create: '/users',
      update: '/users/:id',
      remove: '/users/:id',
      bulk: {
        remove: 'users'
      },
      permissions: '/users/:id/permissions'
    },
    all: '/users/all',
    permissions: {
      list: '/users/permissions',
      listFull: '/users/permissions/full',
      view: '/users/permissions',
      save: '/users/permissions',
      role: '/users/permissions/:role',
      roles: '/users/permissions/roles'
    },
    getCurrentUser: '/users/me',
    enable2FAStep1: '/users/me/enable-2fa-step1',
    enable2FAStep2: '/users/me/enable-2fa-step2',
    disable2FA: '/users/me/disable-2fa',
    getOTPAuthQR: '/users/:id/otp-auth-qr'
  },
  domains: {
    management: {
      list: '/domains',
      create: '/domains',
      update: '/domains/:id',
      remove: '/domains/:id',
      sync: '/domains/:id/sync',
      bulk: {
        remove: '/domains',
        edit: '/domains'
      },
      cf: '/domains/cf',
      counts: '/domains/counts',
      fullData: '/domains/get-full-domain-data',
      removeAllDraft: '/domains/remove-all-draft',
      undelete: '/domains/undelete',
      withCp: '/domains/with-cp',
      checkAllowedDomains: '/domains/check-allowed-domains'
    },
    generator: {
      tldsList: '/domains/generator/tlds',
      quickGenerate: '/domains/generator/generate'
    },
    providers: {
      list: '/domains/providers',
      create: '/domains/providers',
      update: '/domains/providers/:id',
      remove: '/domains/providers/:id',
      auth: '/domains/providers/:id/auth',
      nss: '/domains/providers/:id/nss', // -
      bulk: {
        remove: '/domains/providers'
      },
      all: '/domains/providers/all',
      defaultNss: '/domains/providers/:id/default-nss',
      api: {
        domains: {
          list: '/domains/providers/api/:id/domains', // +
          create: '/domains/providers/api/:id/domains', // +
          import: '/domains/providers/api/:id/domains/import', // +
          sync: '/domains/providers/api/:id/domains/sync', // -
          dns: {
            list: '/domains/providers/api/:id/domains/dns', // -
            save: '/domains/providers/api/:id/domains/dns', // -
            remove: '/domains/providers/api/:id/domains/dns' // -
          },
          nss: {
            get: '/domains/providers/api/:id/domains/nss', // -
            save: '/domains/providers/api/:id/domains/nss' // -
          }
        },
        sync: '/domains/providers/:id/api/import-domains'
      }
    },
    cloudflare: {
      list: '/domains/cloudflare',
      create: '/domains/cloudflare',
      auth: '/domains/cloudflare/:id/auth',
      nss: '/domains/cloudflare/:id/nss',
      view: '/domains/cloudflare/:id',
      update: '/domains/cloudflare/:id',
      remove: '/domains/cloudflare/:id',
      bulk: {
        remove: '/domains/cloudflare'
      },
      all: '/domains/cloudflare/all',
      api: {
        zones: {
          list: '/domains/cloudflare/api/:id/zones',
          create: '/domains/cloudflare/api/:id/add-site',
          update: '/domains/cloudflare/api/:id/update-site',
          // update: '/domains/cloudflare/api/:id/zones/:zone_id',
          remove: '/domains/cloudflare/api/:id/zones/:zone_id',
          import: '/domains/cloudflare/api/:id/import-domains',
          status: '/domains/cloudflare/api/:id/sync-status',
          sync: '/domains/cloudflare/api/:id/zone-sync',
          purgeCache: '/domains/cloudflare/api/:id/zones/:zone_id/purge_cache',
          devMode: '/domains/cloudflare/api/:id/zones/:zone_id/development_mode',
          bulkImport: '/domains/cloudflare/api/import-domains'
        },
        dns: {
          create: '/domains/cloudflare/api/:id/zones/:zone_id/dns',
          update: '/domains/cloudflare/api/:id/zones/:zone_id/dns/:dns_zone_id',
          remove: '/domains/cloudflare/api/:id/zones/:zone_id/dns/:dns_zone_id'
        }
      }
    }
  },
  hosting: {
    providers: {
      list: '/hosting/providers',
      create: '/hosting/providers',
      update: '/hosting/providers/:id',
      remove: '/hosting/providers/:id',
      auth: '/hosting/providers/:id/auth',
      bulk: {
        remove: '/hosting/providers'
      },
      all: '/hosting/providers/all'
    },
    servers: {
      list: '/hosting/servers',
      create: '/hosting/servers',
      view: '/hosting/servers/:id',
      update: '/hosting/servers/:id',
      remove: '/hosting/servers/:id',
      auth: '/hosting/servers/:id/auth',
      ip: '/hosting/servers/:id/ip',
      bulk: {
        remove: '/hosting/servers'
      },
      all: '/hosting/servers/all',
      allByProviderId: '/hosting/servers/provider/:provider_id',
      allWithVesta: '/hosting/servers/all-with-vesta',
      api: {
        import: '/hosting/servers/:id/api/import-domains',
        sync: '/hosting/servers/:id/api/sync-domain',
        ips: '/hosting/servers/:id/api/ips',
        users: '/hosting/servers/:id/api/users',
        domains: '/hosting/servers/:id/api/domains',
        cpuStatus: '/hosting/servers/:id/api/cpu-status',
        memoryStatus: '/hosting/servers/:id/api/memory-status',
        diskStatus: '/hosting/servers/:id/api/disk-status',
        changeDomainOwner: '/hosting/servers/:id/api/change-domain-owner',
        domainTransfer: '/hosting/servers/:id/api/domain-transfer',
        restartWeb: '/hosting/servers/:id/api/restart-web'
      }
    }
  },
  emails: {
    list: '/emails',
    create: '/emails',
    auth: '/emails/:id/auth',
    update: '/emails/:id',
    remove: '/emails/:id',
    bulk: {
      remove: 'emails'
    },
    all: '/emails/all'
  },
  services: {
    list: '/services',
    create: '/services',
    children: '/services/:id/children',
    auth: '/services/:id/auth',
    update: '/services/:id',
    remove: '/services/:id',
    bulk: {
      remove: 'services'
    }
  },
  proxies: {
    list: '/proxy',
    create: '/proxy',
    auth: '/proxy/:id/auth',
    status: '/proxy/:id/status',
    clone: '/proxy/:id/clone',
    update: '/proxy/:id',
    remove: '/proxy/:id',
    bulk: {
      remove: 'proxy'
    },
    all: '/proxy/all'
  },
  settings: {
    list: '/settings',
    create: '/settings',
    restore: '/settings/restore',
    update: '/settings/:id',
    remove: '/settings/:id',
    bulk: {
      remove: 'settings'
    }
  },
  tasks: {
    generator: {
      list: '/tasks/generator',
      create: '/tasks/generator',
      update: '/tasks/generator/:id',
      remove: '/tasks/generator/:id',
      bulk: {
        remove: 'tasks/generator'
      },
      restartJobInTask: '/tasks/generator/:id/restart-job-in-task'
    },
    upload: {
      list: '/tasks/upload',
      create: '/tasks/upload',
      update: '/tasks/upload/:id',
      remove: '/tasks/upload/:id',
      bulk: {
        remove: 'tasks/upload'
      },
      upload: '/tasks/upload/upload'
    },
    unpacking: {
      list: '/tasks/unpacking/:parentId',
      create: '/tasks/unpacking/:parentId',
      all: '/tasks/unpacking/all',
      update: '/tasks/unpacking/id/:id',
      remove: '/tasks/unpacking/id/:id',
      bulk: {
        remove: 'tasks/unpacking/:parentId'
      }
    },
    wpg: {
      list: '/tasks/wpg',
      create: '/tasks/wpg',
      update: '/tasks/wpg/:id',
      remove: '/tasks/wpg/:id',
      bulk: {
        remove: 'tasks/wpg'
      }
    },
    sdr: {
      list: '/tasks/sdr',
      create: '/tasks/sdr',
      remove: '/tasks/sdr/:id',
      bulk: {
        remove: 'tasks/sdr'
      },
      controlTask: '/tasks/sdr/:id/control'
    },
    cloning: {
      list: '/tasks/cloning',
      create: '/tasks/cloning',
      update: '/tasks/cloning/:id',
      remove: '/tasks/cloning/:id',
      bulk: {
        remove: 'tasks/cloning'
      }
    },
    lpg: {
      list: '/tasks/lpg',
      create: '/tasks/lpg',
      saveConfig: '/tasks/lpg/config',
      update: '/tasks/lpg/:id',
      remove: '/tasks/lpg/:id',
      bulk: {
        remove: 'tasks/lpg'
      }
    }
  },
  eventLog: {
    list: '/event-log'
  },
  lockedEntities: {
    list: '/locked-entities'
  },
  credentials: {
    view: '/credentials/:id',
    save: '/credentials/:id',
    remove: '/credentials/:id'
  }
}
