import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Get "All" Hosting Providers (Limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getHostingProvidersList = async () => {
  return axios.get(paths.hosting.providers.all)
}

methods.getHostingProviderCredential = async (id) => {
  return axios.post(paths.hosting.providers.auth.replace(':id', id))
}

methods.getHostingProviderCredential = async (id) => {
  return axios.post(paths.hosting.providers.auth.replace(':id', id))
}

export default methods
