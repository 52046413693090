import lockedEntitiesService from '@/services/api/locked-entities.service'

const getDefaultState = (def = {}) => ({
  ...{
    lockedEntities: [],
    delayTimerInit: null
  },
  ...def
})

export default {
  namespaced: true,
  name: 'lockedEntities',
  state: {
    lockedEntities: [],
    delayTimerInit: null
  },
  getters: {
    getLockedEntities: state => state.lockedEntities,
    getDelayTimerInit: state => state.delayTimerInit
  },
  actions: {
    async initLockedEntities({ dispatch, commit }) {
      await commit('lockedEntities/clearTimerDelay', {}, { root: true })
      const delayTimer = setTimeout(async () => {
        const response = await lockedEntitiesService.getLockedEntities()
        if (response.status === 200) {
          await commit('lockedEntities/setLockedEntities', response.data.result, { root: true })
          return response.data.result
        }
      }, 1000)
      await commit('lockedEntities/setTimerDelay', delayTimer, { root: true })
    }
  },
  mutations: {
    setLockedEntities(state, data) {
      state.lockedEntities = data
    },
    lockedEntity(state, id) {
      if (state.lockedEntities.indexOf(id) < 0) {
        state.lockedEntities.push(id)
      }
    },
    unlockedEntity(state, id) {
      if (state.lockedEntities.indexOf(id) > -1) {
        state.lockedEntities.splice(state.lockedEntities.indexOf(id), 1)
      }
    },
    setTimerDelay(state, timer) {
      state.delayTimerInit = timer
    },
    clearTimerDelay(state) {
      clearTimeout(state.delayTimerInit)
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }

}
