import domainsGeneratorService from '@/services/api/domains/generator.service'

const getDefaultState = (def = {}) => ({
  ...{
    tldsList: [],
    quickGenRes: []
  },
  ...def
})

export default {
  namespaced: true,
  name: 'domainsGenerator',
  state: getDefaultState(),
  getters: {
    getTldsList: state => state.tldsList,
    getQuickGenRes: state => state.quickGenRes
  },
  actions: {
    async quickGenerate({ dispatch, commit }, { data }) {
      data.tlds = data.tlds.join()
      const response = await domainsGeneratorService.quickGenerate(data)
      if (response.status === 200) {
        await commit('domainsGenerator/setQuickGenRes', response.data, { root: true })
      }
      return response.data
    },
    async getTldsList({ dispatch, commit }) {
      try {
        const response = await domainsGeneratorService.getTldsList()
        if (response.status === 200) {
          await commit('domainsGenerator/setTldsList', response.data, { root: true })
        }
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    }
  },
  mutations: {
    setTldsList(state, data) {
      state.tldsList = data
    },
    setQuickGenRes(state, data) {
      state.quickGenRes = data
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
