import axios from 'axios'
import paths from '../../paths'

const getSettings = async (payload) => {
  return axios.get(paths.settings.get, payload)
}

const restoreSettings = async (payload) => {
  return axios.get(paths.settings.restore, payload)
}

export default {
  getSettings,
  restoreSettings
}
