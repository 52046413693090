<template>
  <v-dialog
    v-model="dialog"
    :max-width="`${options.width ?`${options.width}px` : ''}`"
    :style="{ zIndex: options.zIndex }"
    :persistent="options.persistent"
    :hide-overlay="options.hideOverlay"
    @keydown.esc="close"
  >
    <v-card>
      <v-btn
        v-if="options.closeBtn === true"
        icon
        :color="`#312f2f`"
        class="close-btn"
        @click.native="closePictureDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-img
              :src="image.url"
              :lazy-src="image.url"
              @error="errorHandler(image)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DialogImage',
  data() {
    return {
      dialog: false,
      options: {
        width: 800,
        height: 600,
        hideOverlay: false,
        zIndex: 200,
        persistent: false,
        closeBtn: true
      },
      image: {}
    }
  },
  methods: {
    closePictureDialog() {
      this.dialog = false
      this.$emit('onClosePictureDialog')
    },
    open (url, options = {}) {
      this.dialog = true
      this.options = Object.assign(this.options, options)
      this.image = typeof url === 'object' ? Object.assign({}, this.image, url) : { url }
    },
    close () {
      this.dialog = false
      this.$emit('onClosePictureDialog')
    },
    errorHandler(i) {
      i.url = require('@/assets/img/700x700.png')
    }
  }
}
</script>

<style scoped>
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 255;
}
</style>
