import axios from 'axios'
import paths from '../../../paths'

const methods = {}

methods.restartJobInTask = async (id, data) => {
  return axios.post(paths.tasks.generator.restartJobInTask.replace(':id', id), data)
}

export default methods
