import Vue from 'vue'
import io from 'socket.io-client'

import Storage from '@/libs/storage/Storage'

const socket = io(process.env.VUE_APP_SOCKET_URL, {
  forceNew: true,
  autoConnect: false,
  reconnection: false,
  secure: true,
  withCredentials: true,
  transports: ['websocket', 'polling'],
  rejectUnauthorized: false,
  auth: (cb) => {
    const req = { token: Storage.getItem('accessToken') }
    return cb(req)
  }
})

Vue.socket = socket
Vue.prototype.$socket = socket

export default socket
