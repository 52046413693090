class Validator {
  form

  constructor () {
    this.form = null
  }

  /**
   * Email validator
   * @param message
   * @returns {function(*)}
   */
  email (message) {
    if (!message) {
      message = 'Invalid email address format.'
    }

    return v => {
      return /^\w+([.\-+]?\w+)*\+*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(v) || message
    }
  }

  /**
   * Max length validator
   * @param {Number} length
   * @param {String} message
   * @returns {function(*): *|boolean}
   */
  max (length, message) {
    if (!message) {
      message = `Max ${length} characters allowed.`
    }

    return v => {
      return v && v.length > length ? message : true
    }
  }

  /**
   * Min length validator
   * @param length
   * @param message
   * @returns {function(*): *|boolean}
   */
  min (length, message) {
    if (!message) {
      message = `Min ${length} characters allowed.`
    }

    return v => {
      return v && v.length < length ? message : true
    }
  }

  /**
   * Required validator
   * @param {String} message
   * @returns {function(*): *|boolean}
   */
  req (message) {
    if (!message) {
      message = 'This field is required.'
    }

    return v => {
      return !v || (v.length && v.length < 1) ? message : true
    }
  }

  /**
   * Check validation form
   * @param {Object} form
   * @returns {*|undefined}
   */
  formValid (form = null) {
    this.form = form !== null ? form : this.form
    return this.form !== null ? this.form.validate() : undefined
  }
}

export default Validator
