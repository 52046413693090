import Vue from 'vue'
import store from '@/store'

Vue.prototype.$toast = {
  /**
   * Set success toast(s)
   * @param {String|Array} txt
   * @returns {Promise<void>}
   */
  success: async (txt) => {
    await Vue.prototype.$toast._toast(txt, 'success')
  },
  /**
   * Set error toast(s)
   * @param {String|Array} txt
   * @returns {Promise<void>}
   */
  error: async (txt) => {
    await Vue.prototype.$toast._toast(txt, 'error')
  },
  /**
   * Set toast(s)
   * @param {String|Array} txt
   * @param {String} type
   * @returns {Promise<void>}
   * @private
   */
  _toast: async (txt, type) => {
    const textArr = Array.isArray(txt) ? txt : [txt]
    await textArr.forEach(text => store.dispatch('toasts/toast', { type, text }))
  }
}
