import Vue from 'vue'

const copyToClipboard = async (str) => {
  if (!navigator.clipboard) {
    Vue.prototype.$toast.error('Failed to copy!')
  }
  try {
    await navigator.clipboard.writeText(str)
    Vue.prototype.$toast.success('Copied to clipboard')
  } catch (err) {
    Vue.prototype.$toast.error('Failed to copy!')
  }
}

Vue.prototype.$copyToClipboard = copyToClipboard

export default copyToClipboard
