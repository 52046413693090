import servicesService from '@/services/api/services.service'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'services',
  state: {},
  getters: {},
  actions: {
    /**
     * Get service Credentials
     * @param {Function} dispatch
     * @param {String} id
     * @returns {Promise<*>}
     */
    async getServiceCredentials({ dispatch }, { id }) {
      try {
        const response = await servicesService.getServiceCredentials(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
    },
    /**
     * Update service children
     * @param {Function} dispatch
     * @param {String} id
     * @param {Object} data
     * @return {Promise<*>}
     */
    async getServiceChildren({ dispatch }, id) {
      try {
        const response = await servicesService.getServiceChildren(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
    },
    /**
     * Update service children
     * @param {Function} dispatch
     * @param {String} id
     * @param {Object} data
     * @return {Promise<*>}
     */
    async updateServiceChildren({ dispatch }, { id, data }) {
      try {
        const response = await servicesService.updateServiceChildren(id, data)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
