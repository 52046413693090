<template>
  <v-menu
    v-model="showMenu"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <div>
        <v-btn
          class="btnLocaleActivation"
          style="margin: 0 auto"
          text
          v-on="on"
        >
          <v-icon>mdi-earth</v-icon>
          &nbsp;{{ displayLocale }}
        </v-btn>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, i) in langs"
        :key="`Lang${i}`"
        active-class="white--text"
        :value="item.lang"
        :class="[item.class]"
        @click="switchLocale(item.lang)"
      >
        <country-flag
          :country="item.flag"
        />
        <!--        <v-list-item-title class="ml-3">-->
        <!--        </v-list-item-title>-->
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  components: {
    CountryFlag
  },
  data() {
    return {
      showMenu: false,
      langs: [
        {
          lang: 'en',
          flag: 'gb',
          class: 'btnEN '
        },
        {
          lang: 'ua',
          flag: 'ua',
          class: 'btnUA'
        }
      ]
    }
  },
  computed: {
    displayLocale() {
      return this.$i18n.locale
    }
  },
  methods: {
    switchLocale(lang) {
      this.$store.dispatch('locale/setLocale', lang)
    }
  }
}
</script>

<style>
span.flag {
  margin: 0 !important;
}
.flag {
  /*width: 118px !important;*/
}
/*.v-list-item {*/
/*  min-width: 185px !important;*/
/*}*/
</style>
