import Vue from 'vue'

const autocompleteFix = {
  install(Vue) {
    Vue.mixin({
      mounted() {
        this.autocompleteFix()
      },
      methods: {
        autocompleteFix() {
          const elements = document.querySelectorAll('[autocomplete="off"]')
          if (!elements) {
            return
          }
          elements.forEach(element => {
            element.setAttribute('readonly', 'readonly')
            element.style.backgroundColor = 'inherit'
            setTimeout(() => {
              element.removeAttribute('readonly')
            }, 500)
          })
        }
      }
    })
  }
}

Vue.use(autocompleteFix)

export default autocompleteFix
