import store from '@/store'

let maxToastId = 0

const getDefaultState = (def = {}) => ({
  ...{
    toasts: [],
    toastIndex: 0
  },
  ...def
})

const interval = {}

const getters = {
  getToasts: state => state.toasts
}

const actions = {
  toast({ commit }, payload) {
    const id = ++maxToastId
    commit('ADD_TOAST', { type: payload.type, text: payload.text, id })
    interval[id] = setTimeout(() => store.commit('toasts/DELETE_TOAST', id), 5000)
  }
}

const mutations = {
  /**
   * Mutation for adding toasts to queue
   * @param {Object} state
   * @param {Object} payload
   */
  ADD_TOAST(state, payload) {
    state.toasts.unshift(payload)
  },
  /**
   * Mutation for deleting toasts
   * @param {Object} state
   * @param {Object} payload
   */
  DELETE_TOAST(state, id) {
    clearTimeout(interval[id])
    state.toasts = state.toasts.filter(t => t.id !== id)
  },
  SET_TOASTS_STATE(state, toasts) {
    state.toasts = toasts
  },
  /**
   * Mutation for clearing all toasts
   * @param {Object} state
   * @param {Object} payload
   */
  CLEAR_ALL_TOASTS(state) {
    state.toasts = []
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  name: 'toasts',
  state: getDefaultState(),
  getters,
  actions,
  mutations
}
