import cloudflareService from '@/services/api/domains/cloudflare.service'
import helper from '@/utils/helper'
import Vue from 'vue'
import Store from '@/store'

/**
 * Update resource table data
 * @param {Object} data
 */
const updateRecordInResourceTable = (data) => {
  Vue.prototype.$refreshData.updateRecordInResourceTable('domains.cloudflare', data)
  Vue.prototype.$refreshData.leftSidebarDomainsCounters()
  Vue.prototype.$refreshData.reloadResourceTable('domains.cloudflare')

  Store.commit('cloudflare/setDomainsCloudflareList', data, { root: true })
}

const getDefaultState = (def = {}) => ({
  ...{
    domainsCloudflareList: {}
  },
  ...def
})

export default {
  namespaced: true,
  name: 'cloudflare',
  state: getDefaultState(),
  getters: {
    getDomainsCloudflareList: (state) => state.domainsCloudflareList
  },
  actions: {
    /* --------------------------------------------- */
    // ACCOUNT
    /* --------------------------------------------- */
    async getAllCloudflareAccounts() {
      try {
        const response = await cloudflareService.getAllCloudflareAccounts()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const cloudflare = response.data[key]
            data.push({ text: cloudflare.name, value: cloudflare.id })
          })
          return data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getCloudflareCredentials({ dispatch }, { id }) {
      try {
        const response = await cloudflareService.getCloudflareCredentials(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getAccountNSS({ dispatch }, { id }) {
      try {
        const response = await cloudflareService.getAccountNSS(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async importDomains({ dispatch, commit }, { id }) {
      await cloudflareService.importDomains(id)
    },
    async bulkImportDomains({ dispatch, commit }, { ids }) {
      await cloudflareService.bulkImportDomains(ids)
    },
    /* --------------------------------------------- */
    // ZONE
    /* --------------------------------------------- */
    async zoneSave({ dispatch, commit }, data) {
      const response = await cloudflareService.zoneSave(data)
      if (response.status === 200) {
        // updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    async zoneStatus({ dispatch, commit }, { id, zoneId }) {
      try {
        const response = await cloudflareService.zoneStatus(id, zoneId)
        if (response.status === 200) {
          await dispatch('domains/getDomainsByCloudflareId', { id }, { root: true })
          return response.data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async zoneSync({ dispatch, commit }, { id, zoneId, name }) {
      const response = await cloudflareService.zoneSync(id, zoneId, name)
      if (response.status === 200) {
        // updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    async zoneRemove({ dispatch, commit }, { id, zoneId, name }) {
      const response = await cloudflareService.zoneRemove(id, zoneId, name)
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    async purgeCache({ dispatch, commit }, { id, zoneId }) {
      const response = await cloudflareService.purgeCache(id, zoneId)
      if (response.status === 200) {
        // await commit('__domains.cloudflare/saveItem', response.data.result, { root: true })
      }
      return response.data
    },
    async setDevMode({ dispatch, commit }, { id, zoneId, value }) {
      const response = await cloudflareService.setDevMode(id, zoneId, value)
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    /* --------------------------------------------- */
    // DNS
    /* --------------------------------------------- */
    async dnsSave({ dispatch, commit }, data) {
      const response = await cloudflareService.dnsSave(data)
      if (response.status === 200) {
        if (helper.has(response.data, 'success') && response.data.success === true) {
          updateRecordInResourceTable(response.data.result)
        }
        return response.data
      }
    },
    async dnsAdd({ dispatch, commit }, { id, zoneId, type, name, content, ttl, proxied }) {
      const response = await cloudflareService.dnsAdd(id, zoneId, type, name, content, ttl, Boolean(proxied))
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    async dnsUpdate({ dispatch, commit }, { id, zoneId, dnsZoneId, type, name, content, ttl, proxied }) {
      const response = await cloudflareService.dnsUpdate(id, zoneId, dnsZoneId, type, name, content, ttl, Boolean(proxied))
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    },
    async dnsRemove({ dispatch, commit }, { id, zoneId, dnsZoneId }) {
      const response = await cloudflareService.dnsRemove(id, zoneId, dnsZoneId)
      if (response.status === 200) {
        updateRecordInResourceTable(response.data.result)
      }
      return response.data
    }
  },
  mutations: {
    setDomainsCloudflareList(state, data) {
      state.domainsCloudflareList = data
    },
    /**
     * Reset state
     * @param state
     */
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
