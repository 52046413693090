import Vue from 'vue'

const HEALTHY_THRESHOLD = 10000

const getDefaultState = () => ({
  connected: false,
  socketServers: {}
})

export default {
  namespaced: true,
  name: 'socket',
  state: getDefaultState(),
  getters: {
    connected: (state) => state.connected,
    socketServers: (state) => state.socketServers
  },
  mutations: {
    socketConnect (state) {
      state.connected = true
    },
    socketDisconnect (state) {
      state.connected = false
    },
    initAppState (state, payload) {
      payload.refreshData.healthy = true
      payload.refreshData.lastPing = Date.now()
      payload.refreshData.healthyThreshold = 0

      Vue.set(state.socketServers, payload.inst, payload.refreshData)
    },
    appState (state, payload) {
      payload.refreshData.healthy = true
      payload.refreshData.healthyThreshold = 0
      payload.refreshData.lastPing = Date.now()

      // state.healthy = true
      // state.lastPing = payload.refreshData.lastPing
      // state.clientsCount = payload.refreshData.clientsCount
      // state.uptime = payload.refreshData.uptime
      // state.lockedProcesses = payload.refreshData.lockedProcesses

      Vue.set(state.socketServers, payload.inst, payload.refreshData)
    },
    updateHealthyStatus (state) {
      // state.healthy = Date.now() - state.lastPing < HEALTHY_THRESHOLD

      Object.keys(state.socketServers).map((k) => {
        state.socketServers[k].healthy = Date.now() - state.socketServers[k].lastPing < HEALTHY_THRESHOLD * (k + 1)
        state.socketServers[k].healthyThreshold = Date.now() - state.socketServers[k].lastPing
      })
    },
    socketResetState (state) {
      Object.assign(state, getDefaultState())
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
