import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Getting full list of affiliates teams
 * @returns {AxiosPromise<any>}
 */
methods.all = async () => {
  return axios.get(paths.affiliates.teams.all)
}

/**
 * Save Permissions
 * @returns {AxiosPromise<any>}
 */
methods.updateTeamPermissions = async (data) => {
  return axios.post(paths.affiliates.teams.permissions.replace(':id', data.id), { permissions: data.permissions })
}

export default methods
