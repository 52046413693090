<template>
  <div class="toasts-container">
    <v-scroll-x-transition
      group
    >
      <v-alert
        v-for="(message, i) in toasts"
        :key="i"
        dismissible
        elevation="6"
        :type="message.type"
      >
        {{ message.text }}
      </v-alert>
    </v-scroll-x-transition>
  </div>
</template>

<script>
export default {
  name: 'Container',
  data() {
    return {}
  },
  computed: {
    toasts() {
      return this.$store.getters['toasts/getToasts']
    }
  },
  methods: {
    beforeLeave(v) {}
  }
}
</script>

<style lang="scss">
.toasts-container {
  z-index: 999;
  position: fixed;
  top: 25px;
  right: 25px;
}
</style>
