import Storage from '@/libs/storage/Storage'

const getDefaultState = (def = {}) => ({
  ...{
    drawer: (Storage.getItem('drawer') || 'true') === 'true',
    dialogEventLog: (Storage.getItem('dialogEventLog') || 'false') === 'true'
  },
  ...def
})

// const state = {
//   drawer: (Storage.getItem('drawer') || 'true') === 'true',
//   dialogEventLog: (Storage.getItem('dialogEventLog') || 'false') === 'true'
// }

const getters = {
  drawer: (state) => Boolean(state.drawer),
  dialogEventLog: (state) => Boolean(state.dialogEventLog)
}

const actions = {
  toggleDrawerMenu({ commit }, data) {
    commit('toggleDrawerMenu', data)
  },
  toggleDialogEventLog({ commit }, data) {
    commit('toggleDialogEventLog', data)
  }
}

const mutations = {
  /**
   * Mutation for toggle drawer menu
   * @param state
   * @param data
   */
  toggleDrawerMenu(state, data = null) {
    state.drawer = data !== null ? data : !state.drawer
    Storage.setItem('drawer', state.drawer || 'false')
  },
  /**
   * Mutation for toggle dialog event log
   * @param state
   * @param data
   */
  toggleDialogEventLog(state, data = null) {
    state.dialogEventLog = data !== null ? data : !state.dialogEventLog
    Storage.setItem('dialogEventLog', state.dialogEventLog || 'false')
  },
  /**
   *
   * @param state
   */
  resetState (state) {
    Object.assign(state, getDefaultState({ dialogEventLog: state.dialogEventLog, drawer: state.drawer }))
  }
}

export default {
  namespaced: true,
  name: 'adminLayout',
  state: getDefaultState(),
  getters,
  actions,
  mutations
}
