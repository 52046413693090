import affiliatesTeamsService from '@/services/api/affiliates/teams.service'
import permissionsService from '@/services/api/users/permissions.service'

const getDefaultState = (def = {}) => ({
  ...{
    permissionsList: {}
  },
  ...def
})

export default {
  namespaced: true,
  name: 'affiliatesTeams',
  state: getDefaultState(),
  getters: {
    permissionsList: state => state.permissionsList
  },
  actions: {
    async all() {
      try {
        const response = await affiliatesTeamsService.all()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const affiliatesTeam = response.data[key]
            data.push({ text: `${affiliatesTeam.name} ${affiliatesTeam.team_id ? ` | ${affiliatesTeam.team_id}` : ''}`, value: affiliatesTeam.id })
          })
          return data
        }
      } catch (e) {
        // e
      }
    },
    async getPermissionsList (context) {
      const response = await permissionsService.getPermissionsList()
      if (response.status === 200) {
        // return response
        const data = response.data
        context.commit('setPermissionsList', data)
      }
    },
    async saveTeamPermissions(context, payload) {
      const { id, permissions } = payload
      const response = await affiliatesTeamsService.updateTeamPermissions({ id, permissions })
      if (response.status === 200) {
        await context.commit('__affiliates.teams/saveItem', response.data, { root: true })
      }
    }
  },
  mutations: {
    setPermissionsList (state, data) {
      state.permissionsList = data
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
