import axios from 'axios'
import paths from '../../../paths'

const methods = {}

methods.addTask = async (data) => {
  return axios.post(paths.tasks.sdr.create, data)
}

methods.controlTask = async (id, data) => {
  return axios.post(paths.tasks.sdr.controlTask.replace(':id', id), data)
}
methods.updateTask = methods.controlTask
methods.removeTask = methods.controlTask

export default methods
