import axios from 'axios'
import paths from '../../paths'

const methods = {}

/**
 *
 * @param id
 * @returns {AxiosPromise<any>}
 */
methods.getEmailCredentials = async (id) => {
  return axios.post(paths.emails.auth.replace(':id', id))
}
/**
 *
 * @param id
 * @returns {AxiosPromise<any>}
 */
methods.getAllEmails = async (query) => {
  return axios.get(paths.emails.all, { params: { query } })
}

export default methods
