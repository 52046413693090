import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Get "All" Domains Providers List (limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getDomainsProvidersList = async () => {
  return axios.get(paths.domains.providers.all)
}

/**
 * Get "All" Domains Providers List (limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getDefaultNss = async (id) => {
  return axios.get(paths.domains.providers.defaultNss.replace(':id', id))
}

/**
 * Get Domains Provider Credentials
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getDomainsProviderCredentials = async (id) => {
  return axios.post(paths.domains.providers.auth.replace(':id', id))
}

/* -------------------------------------------------- */

/**
 * Get Domains Provider Credentials
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.domainAdd = async (id, name) => {
  return axios.post(paths.domains.providers.api.domains.create.replace(':id', id), { name })
}

/**
 * Get Domains Provider Credentials
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.importDomains = async (id) => {
  return axios.get(paths.domains.providers.api.domains.import.replace(':id', id))
}

/**
 * Get Domains Provider Credentials
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.domainSync = async (id, name) => {
  return axios.post(paths.domains.providers.api.domains.sync.replace(':id', id), { name })
}

/**
 * Set domain dns record
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.nssSet = async (id, domain, nss) => {
  return axios.post(paths.domains.providers.api.domains.nss.save.replace(':id', id), { domain, nss })
}

/**
 * Set domain dns record
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.dnsSet = async (id, domain, subdomain, content, type) => {
  return axios.post(paths.domains.providers.api.domains.dns.save.replace(':id', id), { domain, subdomain, content, type })
}

/**
 * Remove domain dns record
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.dnsRemove = async (id, domain, subdomain, content, type) => {
  return axios.delete(paths.domains.providers.api.domains.dns.remove.replace(':id', id), { data: { domain, subdomain, content, type } })
}

export default methods
