import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Import affiliates
 * @returns {AxiosPromise<any>}
 */
methods.getAllUsers = async () => {
  return axios.get(paths.users.all)
}

/**
 * Enable 2FA Step 1
 * @returns {AxiosPromise<any>}
 */
methods.enable2FAStep1 = async () => {
  return axios.post(paths.users.enable2FAStep1)
}

/**
 * Enable 2FA Step 2
 * @returns {AxiosPromise<any>}
 */
methods.enable2FAStep2 = async (data) => {
  return axios.post(paths.users.enable2FAStep2, data)
}

/**
 * Enable 2FA Step 2
 * @returns {AxiosPromise<any>}
 */
methods.disable2FA = async (data) => {
  return axios.delete(paths.users.disable2FA, { data })
}

/**
 * Get OTP Auth QR
 * @returns {AxiosPromise<any>}
 */
methods.getOTPAuthQR = async (userId) => {
  return axios.get(paths.users.getOTPAuthQR.replace(':id', userId))
}

/**
 * Update User Permissions
 * @param userId
 * @param permissions
 * @returns {AxiosPromise<any>}
 */
methods.updateUserPermissions = async (userId, permissions) => {
  return axios.patch(paths.users.management.permissions.replace(':id', userId), { permissions })
}

export default methods
