<template>
  <v-dialog
    v-model="dialog"
    :hide-overlay="hideOverlay"
    :max-width="`${width}px`"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error darken-1"
          text
          @click="close"
        >
          {{ cancelText || $t('dialog.confirm.cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="getConfirmLoading"
          @click="confirm"
        >
          {{ confirmText || $t('dialog.confirm.confirm') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 500
    },
    title: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    confirmLoading: {
      type: Boolean,
      default: false
    },
    hideOverlay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getConfirmLoading() {
      return this.confirmLoading
    }
  },
  mounted () {

  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>

</style>
