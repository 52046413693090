import domainsService from '@/services/api/domains/domains.service'

const getDefaultState = (def = {}) => ({
  ...{
    domains: [],
    counts: [],
    domainsFullData: {}
  },
  ...def
})

export default {
  namespaced: true,
  name: 'domains',
  state: getDefaultState(),
  getters: {
    domains: (state) => state.domains,
    counts: (state) => state.counts,
    domainsFullData: (state) => state.domainsFullData
  },
  actions: {
    async bulkEditing({ dispatch, commit }, data) {
      return domainsService.bulkEditing(data)
    },
    async syncDomainDns({ dispatch, commit }, id) {
      return domainsService.syncDomainDns(id)
    },
    async getDomainsCountsByStatuses({ dispatch, commit }) {
      const domainsCounts = {}
      const response = await domainsService.getDomainsCountsByStatuses()
      if (response.status === 200) {
        const result = response.data.result
        Object.values(result.keys).map((k, i) => {
          domainsCounts[k] = result.counters[i]
        })
        await commit('domains/setCounts', domainsCounts, { root: true })
      }
      return domainsCounts
    },
    async getDomainFullData({ dispatch, commit }, name) {
      const response = await domainsService.getDomainFullData(name)
      if (response.status === 200) {
        await commit('domains/setDomainFullData', response.data, { root: true })
      }
      return response
    },
    async removeAllDraft({ dispatch, commit }) {
      const response = await domainsService.removeAllDraft()
      if (response.status === 204) {
        await dispatch('getDomainsCountsByStatuses')
        await dispatch('__domains.management/list', null, { root: true })
      }
    },
    async undelete({ dispatch, commit }, data) {
      const response = await domainsService.undelete(data)
      if (response.status === 204) {
        await dispatch('getDomainsCountsByStatuses')
        await dispatch('__domains.management/list', null, { root: true })
      }
    },
    async searchDomainsWithCp({ dispatch, commit }, q) {
      const response = await domainsService.searchDomainsWithCp({ q })
      if (response.status === 200) {
        // await commit('domains/setDomainFullData', response.data, { root: true })
        return response.data
      }
      return response
    },
    async checkAllowedDomains({ dispatch, commit }, data) {
      const response = await domainsService.checkAllowedDomains(data)
      if (response.status === 200) {
        // await commit('domains/setDomainFullData', response.data, { root: true })
        return response.data
      }
      return response
    }
  },
  mutations: {
    setCounts(state, data) {
      state.counts = data
    },
    setDomainFullData(state, data) {
      state.domainsFullData = Object.assign({}, state.domainsFullData, { [data.name]: data })
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
