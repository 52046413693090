import emailsService from '@/services/api/emails.service'

const getDefaultState = (def = {}) => ({
  ...{
    emails: []
  },
  ...def
})

export default {
  namespaced: true,
  name: 'emails',
  state: getDefaultState(),
  getters: {
    emails: state => state.emails
  },
  actions: {
    /**
     * Get Email Credentials
     * @param {Function} dispatch
     * @param {String} id
     * @returns {Promise<*>}
     */
    async getEmailCredentials({ dispatch }, { id }) {
      try {
        const response = await emailsService.getEmailCredentials(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
    },
    /**
     * Get Email Credentials
     * @param {Function} dispatch
     * @param {String} id
     * @returns {Promise<*>}
     */
    async getAllEmails({ dispatch, commit, getters }, query = undefined) {
      try {
        const response = await emailsService.getAllEmails(query)
        if (response.status === 200) {
          if ((response.data || []).length > 0) {
            commit('emails', { data: response.data, getters })
          }
        }
      } catch (e) {
        console.log(e)
        // e
      }
    }
  },
  mutations: {
    emails(state, { data, getters }) {
      data.map((emailObj) => {
        if (getters.emails.find((e) => e.text === emailObj.email) === undefined) {
          state.emails.push({ text: emailObj.email, value: emailObj.id })
        }
        return emailObj
      })
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
