import Vue from 'vue'
import refreshData from '../utils/refreshData'

const plugin = {
  install () {
    refreshData.setEventBus(Vue.prototype.$eventBus)
    Vue.refreshData = refreshData
    Vue.prototype.$refreshData = refreshData
  }
}

Vue.use(plugin)
