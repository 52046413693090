import Vue from 'vue'
import store from '../store'
import axios from 'axios'
import Storage from '../libs/storage/Storage'
import router from '../router'
import paths from '../paths'

/**
 * Axios default options
 */
axios.defaults.baseURL = process.env.VUE_APP_API_URL || process.env.BASE_URL || ''

axios.defaults.headers.common['Accept-Language'] = Storage.getItem('locale') || process.env.VUE_APP_I18N_FALLBACK_LOCALE

axios.defaults.headers.common.accessToken = store.getters['auth/accessToken'] || ''
axios.defaults.headers.common.refreshToken = store.getters['auth/refreshToken'] || ''

/**
 * Checking URL in Auth URLs
 * @param {String} url
 * @returns {boolean}
 */
const notAuthUrl = (url) => {
  return [
    paths.auth.login,
    paths.auth.refreshTokens,
    paths.auth.refreshAccessToken
  ].every(uri => url.indexOf(uri) === -1)
}

/**
 * Axios requests wrapper
 */
axios.interceptors.request.use(
  request => {
    if (notAuthUrl(request.url)) {
      const JWTToken = store.getters['auth/accessToken']
      if (JWTToken) {
        request.headers = { ...request.headers, ...{ Authorization: `Bearer ${JWTToken}` } }
      }
    }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * Axios responses wrapper
 */
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (err) => {
    if (err.response === undefined) {
      return err
    }

    // Other errors
    if (err.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(err)
      })
    }

    // Set original request
    const originalRequest = err.config

    // Authorization error
    if (originalRequest.url.includes(paths.auth.login) || originalRequest.url.includes(paths.auth.loginWithOneTimeToken)) {
      return new Promise((resolve, reject) => {
        reject(err)
      })
    }

    // Error while refreshing token
    if (originalRequest.url.includes(paths.auth.refreshAccessToken) && !originalRequest._retry) {
      originalRequest._retry = true
      await store.dispatch('auth/refreshToken').catch(() => {})
      return axios(originalRequest)
    }

    // Error while refreshing token
    if (originalRequest.url.includes(paths.auth.refreshTokens)) {
      await router.push({ name: 'logout' }).catch(() => {})
      return new Promise((resolve, reject) => {
        reject(err)
      })
    }

    if (err.response.status === 401 && !originalRequest._retryAccess) {
      // originalRequest._retry = true
      originalRequest._retryAccess = true
      await store.dispatch('auth/refreshAccessToken').catch(() => {})
      return axios(originalRequest)
    }
    // else if (err.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true
    //   await store.dispatch('auth/refreshToken').catch(() => {})
    //   return axios(originalRequest)
    // }

    return Promise.reject(err)
  }
)

/**
 * Install plugin fn
 * @param {Object} Vue
 * @returns {*}
 */
Vue.use({
  install (Vue) {
    Vue.axios = axios
    window.axios = axios
    Object.defineProperties(Vue.prototype, {
      axios: {
        get () {
          return axios
        }
      },
      $axios: {
        get () {
          return axios
        }
      }
    })
  }
})

export default Plugin
