import axios from 'axios'
import paths from '../../paths'

const methods = {}

/**
 *
 * @param id
 * @returns {AxiosPromise<any>}
 */
methods.getServiceCredentials = async (id) => {
  return axios.post(paths.services.auth.replace(':id', id))
}

/**
 *
 * @param id
 * @returns {AxiosPromise<any>}
 */
methods.getServiceChildren = async (id) => {
  return axios.get(paths.services.children.replace(':id', id))
}

/**
 *
 * @param id
 * @param data
 * @returns {AxiosPromise<any>}
 */
methods.updateServiceChildren = async (id, data) => {
  return axios.post(paths.services.children.replace(':id', id), data)
}

export default methods
