import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Permissions
 * @returns {AxiosPromise<any>}
 */
methods.getPermissionsList = async (role) => {
  return axios.get(paths.users.permissions.listFull, { params: { role } })
}

/**
 * Roles
 * @returns {AxiosPromise<any>}
 */
methods.getRolesList = async () => {
  return axios.get(paths.users.permissions.roles)
}

/**
 * Permissions by role
 * @returns {AxiosPromise<any>}
 */
methods.getPermissionsForRole = async (role) => {
  return axios.get(paths.users.permissions.role.replace(':role', role))
}

/**
 * Save Permissions
 * @returns {AxiosPromise<any>}
 */
methods.savePermissions = async (data) => {
  return axios.post(paths.users.permissions.save, data)
}

export default methods
