import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import store from '../store'

// Layouts
const DefaultLayout = () => import('../layouts/DefaultLayout')
const AdminLayout = () => import('../layouts/AdminLayout')

// Auth
const Login = () => import('../views/auth/Login.vue')

// Admin -> Dashboard
const Dashboard = () => import('../views/admin/dashboard/Dashboard')

// Admin -> Dashboard
const Tags = () => import('../views/admin/tags/Tags')

// Admin -> Affiliates
const Affiliates = () => import('../views/admin/affiliates/Management')
const AffiliatesTeams = () => import('../views/admin/affiliates/Teams')

// Admin -> Events
const EventsManagement = () => import('../views/admin/events/Management')

// Admin -> Hosting
const HostingProviders = () => import('../views/admin/hosting/Providers.vue')
const HostingServers = () => import('../views/admin/hosting/Servers')

// Admin -> Domains
const DomainsManagement = () => import('../views/admin/domains/Management')
const DomainsProviders = () => import('../views/admin/domains/providers/Providers')
// const DomainsProvidersDomainsReg = () => import('../views/admin/domains/providers/DomainsReg')
const DomainsCloudflare = () => import('../views/admin/domains/Cloudflare')
// const DomainsGenerator = () => import('../views/admin/domains/Generator')

// Admin -> Users
const Users = () => import('../views/admin/users/Management')
const UsersPermissions = () => import('../views/admin/users/Permissions')

// Admin -> Settings
const Settings = () => import('../views/admin/Settings')

// Admin -> Emails
const Emails = () => import('../views/admin/Emails')

// Admin -> Services
const Services = () => import('../views/admin/services/Services.vue')

// Admin -> Emails
const Proxies = () => import('../views/admin/Proxies')

// Admin -> Tasks -> ArchiveUpload
const ArchiveUpload = () => import('../views/admin/tasks/ArchiveUpload')

// Admin -> Tasks -> Generator
const Generator = () => import('../views/admin/tasks/Generator')

// Admin -> Tasks -> Single Domain Registration
const SingleDomainRegistration = () => import('../views/admin/tasks/SingleDomainRegistration')

// Admin -> Tasks -> WhitePagesGenerator
const WhitePagesGenerator = () => import('../views/admin/tasks/WhitePagesGenerator')

// Admin -> Tasks -> Cloning
const Cloning = () => import('../views/admin/tasks/Cloning')

// Admin -> Tasks -> ArchiveUpload
const LandingPagesGenerator = () => import('../views/admin/tasks/LandingPagesGenerator')

const Docs = () => import('../views/admin/Docs')

// Error pages -> Error 404
const PageNotFound = () => import('../views/errors/PageNotFound')

// Error pages -> Error 403
const PageForbidden = () => import('../views/errors/PageForbidden')

Vue.use(VueRouter)
Vue.use(VueMeta)

/**
 * Router push+replace hot-fix
 */
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/admin',
    component: AdminLayout,
    redirect: '/admin/dashboard',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/admin/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          permission: 'dashboard.view'
        }
      },
      {
        path: '/admin/affiliates',
        redirect: '/admin/affiliates/management',
        name: 'affiliates'
      },
      {
        path: '/admin/affiliates/management',
        name: 'affiliatesManagement',
        component: Affiliates,
        meta: {
          permission: 'affiliates.management.list'
        }
      },
      {
        path: '/admin/affiliates/teams',
        name: 'affiliatesTeams',
        component: AffiliatesTeams,
        meta: {
          permission: 'affiliates.teams.list'
        }
      },
      {
        path: '/admin/tags',
        name: 'tags',
        component: Tags,
        meta: {
          permission: 'tags.list'
        }
      },
      {
        path: '/admin/events',
        name: 'events',
        component: EventsManagement,
        meta: {
          permission: 'events.list'
        }
      },
      {
        path: '/admin/hosting/providers',
        name: 'hostingProviders',
        component: HostingProviders,
        meta: {
          permission: 'hosting.providers.list'
        }
      },
      {
        path: '/admin/hosting/servers',
        name: 'hostingServers',
        component: HostingServers,
        meta: {
          permission: 'hosting.servers.list'
        }
      },
      {
        path: '/admin/domains',
        redirect: '/admin/domains/management',
        name: 'domains'
      },
      {
        path: '/admin/domains/management/all',
        name: 'domainsManagementAll',
        props: { status: 'all' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/management/active',
        name: 'domainsManagementActive',
        props: { status: 'active' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/management/pending-registration',
        name: 'domainsManagementPendingRegistration',
        props: { status: 'pendingRegistration' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/management/pending-settings',
        name: 'domainsManagementPendingSettings',
        props: { status: 'pendingSettings' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/management/deleted',
        name: 'domainsManagementDeleted',
        props: { status: 'deleted' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/management/with-errors',
        name: 'domainsManagementWithErrors',
        props: { status: 'withErrors' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/management/locked',
        name: 'domainsManagementLocked',
        props: { status: 'locked' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/management/draft',
        name: 'domainsManagementDraft',
        props: { status: 'draft' },
        component: DomainsManagement,
        meta: {
          permission: 'domains.management.list'
        }
      },
      {
        path: '/admin/domains/providers/accounts',
        name: 'domainsProviders',
        component: DomainsProviders,
        meta: {
          permission: 'domains.providers.list'
        }
      },
      // {
      //   path: '/admin/domains/providers/domains-registrations',
      //   name: 'domainsProvidersDomainsRegistrations',
      //   component: SingleDomainRegistration,
      //   meta: {
      //     permission: 'domains.providers.list'
      //   }
      // },
      {
        path: '/admin/domains/cloudflare',
        name: 'domainsCloudflare',
        component: DomainsCloudflare,
        meta: {
          permission: 'domains.cloudflare.list'
        }
      },
      {
        path: '/admin/users',
        redirect: '/admin/users/management',
        name: 'users'
      },
      {
        path: '/admin/users/management',
        name: 'usersManagement',
        component: Users,
        meta: {
          permission: 'users.management.list'
        }
      },
      {
        path: '/admin/users/permissions',
        name: 'usersPermissions',
        component: UsersPermissions,
        meta: {
          permission: 'users.permissions.view'
        }
      },
      {
        path: '/admin/settings',
        name: 'settings',
        component: Settings,
        meta: {
          permission: 'settings.view'
        }
      },
      {
        path: '/admin/services',
        name: 'services',
        component: Services,
        meta: {
          permission: 'services.list'
        }
      },
      {
        path: '/admin/emails',
        name: 'emails',
        component: Emails,
        meta: {
          permission: 'emails.list'
        }
      },
      {
        path: '/admin/proxies',
        name: 'proxies',
        component: Proxies,
        meta: {
          permission: 'proxies.list'
        }
      },
      {
        path: '/admin/tasks',
        redirect: '/admin/tasks/archive-upload',
        name: 'tasks'
      },
      {
        path: '/admin/tasks/generator',
        name: 'tasksGenerator',
        component: Generator,
        meta: {
          permission: 'tasks.generator.list'
        }
      },
      {
        path: '/admin/tasks/archive-upload',
        name: 'tasksArchiveUpload',
        component: ArchiveUpload,
        meta: {
          permission: 'tasks.upload.list'
        }
      },
      {
        path: '/admin/tasks/white-pages-generator',
        name: 'tasksWhitePagesGenerator',
        component: WhitePagesGenerator,
        meta: {
          permission: 'tasks.wpg.list'
        }
      },
      {
        path: '/admin/tasks/cloning',
        name: 'tasksCloning',
        component: Cloning,
        meta: {
          permission: 'tasks.cloning.list'
        }
      },
      {
        path: '/admin/tasks/sdr',
        name: 'tasksSDR',
        component: SingleDomainRegistration,
        meta: {
          permission: 'tasks.sdr.list'
        }
      },
      {
        path: '/admin/tasks/sdr/:id',
        name: 'tasksSDRDetails',
        component: SingleDomainRegistration,
        meta: {
          permission: 'tasks.sdr.list'
        }
      },
      {
        path: '/admin/tasks/landing-pages-generator',
        name: 'tasksLandingPagesGenerator',
        component: LandingPagesGenerator,
        meta: {
          permission: 'tasks.lpg.list'
        }
      },
      {
        path: '/admin/docs',
        name: 'docs',
        component: Docs
        // meta: {
        //   permission: 'tasks.lpg.list'
        // }
      }
    ]
  },
  {
    path: '/auth/logout',
    name: 'logout',
    async beforeEnter(to, from) {
      await store.dispatch('auth/logout').then((e) => {
        window.location.href = '/login'
      })
    }
  },
  {
    path: '/',
    redirect: '/login',
    component: DefaultLayout,
    meta: {
      guestOnly: true
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login
      },
      {
        path: '/login/:token',
        name: 'loginByToken',
        async beforeEnter(to, from, next) {
          const destination = {
            name: 'dashboard'
          }
          await store.dispatch('auth/loginWithOneTimeToken', to.params.token)
          next(destination)
        }
      }
    ]
  },
  {
    path: '/403',
    name: 'pageForbidden',
    component: PageForbidden
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Before loaded routing
 */
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      await store.dispatch('auth/logout')
      await router.push({ name: 'login', query: to.fullPath.indexOf('logout') === -1 ? { redirect: to.fullPath } : {} })
    } else {
      next()
    }
    if (store.getters['auth/isAuthenticated'] === true && {}.propertyIsEnumerable.call(to.meta, 'permission')) {
      if (!(store.getters['auth/loggedInUser'].roleRights.some((r) => r === to.meta.permission))) {
        next({ name: 'pageForbidden' })
      }
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    store.getters['auth/isAuthenticated']
      ? next({ name: 'dashboard' })
      : next()
  } else {
    next()
  }
})

export default router
