<template>
  <v-card width="100vw">
    <v-card-title class="pa-0">
      <v-toolbar
        flat
        class="table-data-header"
      >
        <v-toolbar-title class="table-data-header-text">
          {{ title }}
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <div
          v-show="hideTopActionBtn === false"
          class="text-no-wrap"
        >
          <v-btn
            v-if="hideTopAction.includes('remove') === false"
            v-show="selectedItems.length > 0"
            v-can="`${resource}.remove`"
            color="error"
            class="mr-3"
            small
            @click="removeSelectedItems"
          >
            {{ $t('tableData.remove') }} ({{ selected.length }})
          </v-btn>
          <v-btn
            v-if="selectedItems.length < 1 && this.$can(`${resource}.create`) && hideTopAction.includes('create') === false"
            v-can="`${resource}.create`"
            color="primary"
            class="mr-3 table-data-top-create-btn"
            small
            @click="createItem"
          >
            {{ $t('tableData.createItem') }}
          </v-btn>
        </div>
        <div>
          <slot name="top-action-buttons" />
        </div>
        <v-spacer />
        <v-text-field
          v-show="!!showSearchField"
          ref="tableHeaderSearch"
          v-model="search"
          :value="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="search ml-3 text-right"
          @blur="showSearchField = !!search && !!showSearchField"
        />
        <v-btn
          v-show="!showSearchField"
          icon
          @click="showSearchForm"
        >
          <v-icon>mdi-text-search</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <div>
      <slot name="top-filter" />
    </div>
    <!-- Table -->
    <v-data-table
      v-model="selected"
      :height="getTableHeight"
      :headers="getFilteredHeaders"
      :fixed-header="true"
      :items="items"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="loading"
      :expanded="expanded"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :footer-props="{
        'items-per-page-options': [50, 100, 200],
      }"
      :server-items-length="totalItems"
      :show-select="showSelect === true && $can(`${resource}.remove`)"
      :item-class="typeof itemClassFn === 'function' ? itemClassFn : () => {}"
      :class="`data-table`"
      mobile-breakpoint="0"
      @update:page="goTo"
      @toggle-select-all="selectAllHandle"
      @item-expanded="itemExpanded"
      @item-selected="selectedHandle"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        v-slot:[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>

      <!--template v-for="(_, someOtherName) in $slots" v-slot:[someOtherName]>
        <slot :name="someOtherName" />
      </template-->

      <template v-slot:top />

      <template #[`item.createdAt`]="{ item }">
        {{ /* item.createdAt | dateFilter */ }}
        <table-date :date="item.createdAt" />
        <slot
          name="dates"
          :item="item"
        />
      </template>

      <template
        v-show="$can(`${resource}.update`) || $can(`${resource}.remove`) || $can(`${resource}.upload`) || $can(`${resource}.download`)"
        #[`item.actions`]="{ item }"
      >
        <div v-if="getActionsDisplayMethod === 'list'">
          <drop-menu
            :actions-list="getActionsList"
            :item="item"
            :loading="item.loading"
          />
        </div>
        <div v-else>
          <div class="actions-cell">
            <slot
              name="action-buttons"
              :item="item"
            />
            <table-btn
              v-if="allowDefaultActions === true"
              :item="item"
              :disabled="item.loading"
              :tooltip-text="$t('tableData.update')"
              :icon="`pencil`"
              :can="`${resource}.update`"
              :fn="updateItem"
            />
            <table-btn
              v-if="allowDefaultActions === true"
              :item="item"
              :disabled="item.loading"
              :tooltip-text="$t('tableData.remove')"
              :icon="`delete`"
              :can="`${resource}.remove`"
              :fn="removeItem"
            />
          </div>
        </div>
      </template>

      <template #no-data>
        <div v-if="!!search">
          <v-btn
            v-show="!!search"
            color="primary"
            @click="search = null"
          >
            {{ $t('tableData.reset') }}
          </v-btn>
        </div>
        <div v-else>
          {{ $t('tableData.empty') }}
        </div>
      </template>
    </v-data-table>

    <dialog-form
      :dialog="dialog"
      :form-title="formTitle"
      :form-scheme="formScheme"
      :form-default-values="formDefaultValues"
      :edited-item="getEditedItem"
      :edited-index="editedIndex"
      :save-loading="saveLoading"
      :submit-text="submitText"
      @changeEditedItem="changeEditedItem"
      @save="save"
      @close="close"
    />

    <v-dialog
      v-model="dialogRemove"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('tableData.deleteDialogTitle') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeRemove"
          >
            {{ $t('tableData.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="removeItemConfirm"
          >
            {{ $t('tableData.OK') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Data',
  filters: {
    dateFilter (date = null) {
      try {
        if (!date) {
          return ''
        }
        if (date.indexOf('T')) {
          const dateArray = date.split('T')
          return dateArray.length === 2 ? `${dateArray[0]} ${dateArray[1].substring(0, 8)}` : ''
        }
      } catch (e) {
        return ''
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tableHeaders: {
      type: Array,
      default: () => []
    },
    defaultTableHeaders: {
      type: Array,
      default: () => [
        'createdAt', 'actions'
      ]
    },
    actionsDisplayMethod: {
      type: String,
      default: ''
    },
    actionsList: {
      type: Array,
      default: () => []
    },
    allowDefaultActions: {
      type: Boolean,
      default: true
    },
    formScheme: {
      type: Object,
      default: () => {
      }
    },
    formDefaultValues: {
      type: Object,
      default: () => {
      }
    },
    tableOptions: {
      type: Object,
      default: () => {
      }
    },
    expanded: {
      type: Array,
      default: () => []
    },
    itemExpanded: {
      type: Function,
      default: () => {
      }
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    singleExpand: {
      type: Boolean,
      default: true
    },
    resource: {
      type: String,
      default: ''
    },
    hideTopActionBtn: {
      type: Boolean,
      default: false
    },
    customOpt: {
      type: Object,
      default: () => {
      }
    },
    itemClassFn: {
      type: Function,
      default: () => {}
    },
    submitText: {
      type: String,
      default: undefined
    },
    hideTopAction: {
      type: Array,
      default: () => []
    },
    showSelect: {
      type: Boolean,
      default: true
    },
    extraTableHeight: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    page: 0,
    numberOfPages: 0,
    dialog: false,
    dialogRemove: false,
    bulkRemoving: false,
    beforeHeaders: [],
    afterHeaders: [],
    headers: [],
    selectedHeaders: [],
    sortBy: '',
    sortDesc: '',
    search: '',
    filter: {},
    delayTimer: null,
    reloadDelayTimer: null,
    selected: [],
    editedItem: {},
    defaultItem: {},
    editedIndex: -1,
    valid: true,
    saveLoading: false,
    needCompacted: false,
    tableHeight: 0,
    showSearchField: false,
    disabledCount: 0
  }),
  computed: {
    options: {
      get () {
        return this.$store.getters[`__${this.getResource}/options`]
      },
      set (value) {
        this.$store.commit(`__${this.getResource}/setOptions`, value)
        window.scroll({ top: 0, behavior: 'smooth' })
      }
    },
    loading () {
      return this.$store.getters[`__${this.getResource}/loading`]
    },
    items: {
      get () {
        const currentItems = this.$store.getters[`__${this.getResource}/items`]
        this.$emit('currentItems', currentItems)
        return currentItems
      },
      set (value) {
        this.$store.commit(`__${this.getResource}/saveItem`, value)
      }
    },
    totalItems () {
      return this.$store.getters[`__${this.getResource}/totalItems`] || 0
    },
    formTitle () {
      return this.editedIndex === -1
        ? this.$t('tableData.createItem')
        : this.$t('tableData.updateItem')
    },
    selectedItems () {
      // return this.selected.length > 0
      return this.$store.getters[`__${this.getResource}/checkedItems`] || []
    },
    // getTableHeaders () {
    //   return [...JSON.parse(JSON.stringify(this.tableHeaders)), ...this.headers]
    // },
    getHeaders () {
      return [...this.beforeHeaders, ...this.tableHeaders, ...this.afterHeaders]
    },
    getFilteredHeaders () {
      return this.selectedHeaders.length > 0 ? this.getHeaders.filter(s => this.selectedHeaders.includes(s)) : this.getHeaders.slice()
    },
    getActionsDisplayMethod () {
      return this.actionsDisplayMethod
    },
    getActionsList () {
      return this.actionsList
    },
    getResource () {
      return this.resource
    },
    // getLockedEntities() {
    //   return this.$store.getters['lockedEntities/getLockedEntities']
    // },
    isNeedCompacted () {
      return this.needCompacted
    },
    getEditedItem () {
      return this.$store.getters[`__${this.resource}/getEditedItem`] || []
    },
    getTableHeight () {
      return this.extraTableHeight ? this.extraTableHeight : this.$pageHeight
    },
    ...mapGetters({ getLockedEntities: 'lockedEntities/getLockedEntities' })
  },
  watch: {
    // tableHeaders(val) {
    //   this.selectedHeaders = val
    // },
    async dialog (val) {
      val || this.close()
      await this.dialogListener(val)
    },
    dialogRemove (val) {
      val || this.closeRemove()
    },
    async editedItem (val) {
      await this.$emit('changeEditedItem', val)
    },
    options: {
      async handler () {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(async () => {
          await this.load()
          // this.selected = []
        }, 200)
      },
      deep: true
    },
    async search (value) {
      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(async () => {
        this.options.page = 1
        this.options.query = value
        await this.load()
      }, 400)
    },
    selected (val) {
      this.$emit('changeSelected', val)
    },
    customOpt (val) {
      if (!val) {
        return false
      }
      this.options = { ...this.options, ...val }
    },
    items (val) {
      val.map((i) => {
        if (i.disabled === true) {
          this.disabledCount += 1
        }
      })
      this.items = val
    },
    getLockedEntities (val) {
      this.selected = val
    },
    extraTableHeight(val) {
      this.tableHeight = val
    }
  },
  async mounted () {
    // Window resize listener
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })

    // this.tableHeight = this.tableHeight - this.extraTableHeight
    // this.selected = this.$store.getters[`__${this.resource}/checkedItems`]

    // Default table columns
    this.addDefaultTableHeaders()

    if (this.actionsDisplayMethod === 'list') {
      if (this.$can(`${this.resource}.update`)) {
        this.actionsList.push({
          icon: 'pencil-outline',
          text: 'Edit item',
          fn: (i) => {
            this.updateItem(i)
          }
        })
      }
      if (this.$can(`${this.resource}.remove`)) {
        this.actionsList.push({
          icon: 'trash-can-outline',
          text: 'Delete item',
          fn: (i) => {
            this.removeItem(i)
          }
        })
      }
      this.actionsList.push({
        divider: true
      })
    }

    this.$eventBus.$on(`${this.resource}_updateItem`, (item) => {
      this.updateItem(item)
    })
    this.$eventBus.$on(`${this.resource}_removeItem`, (item) => {
      this.removeItem(item)
    })

    // Reload data table
    this.$eventBus.$on(`${this.resource}_reloadData`, (data) => {
      clearTimeout(this.reloadDelayTimer)
      this.reloadDelayTimer = setTimeout(async () => {
        await this.load()
      }, 1000)
    })

    // Reload data table
    this.$eventBus.$on(`${this.resource}_updateRecord`, (params) => {
      const { resource, data } = params
      if (data !== null) {
        // If a filter or a search query is specified, no products are added (update only)
        if (!!this.options.query || Object.keys(this.filter).length > 0) {
          if (data instanceof Array) {
            data.map(d => {
              this.$store.commit(`__${resource}/updateItem`, d)
            })
          } else if (data instanceof Object) {
            this.$store.commit(`__${resource}/updateItem`, data)
          }
        } else {
          if (data instanceof Array) {
            data.map(d => {
              this.$store.commit(`__${resource}/saveItem`, d)
            })
          } else if (data instanceof Object) {
            this.$store.commit(`__${resource}/saveItem`, data)
          }
        }
      }
    })

    this.$eventBus.$on(`${this.resource}_updateEditedItem`, (data) => {
      this.editedItem = Object.assign({}, this.editedItem, data)
    })

    // Locked / unlocked entities
    this.$eventBus.$on(`${this.resource}_updateLockedState`, (data) => {
      if (data.id && data.lockState === false) {
        this.$store.commit('lockedEntities/unlockedEntity', data.id)
      }
      if (data.id && data.lockState === true) {
        this.$store.commit('lockedEntities/lockedEntity', data.id)
      }
      this.initLockedEntities(data.resource)
    })

    // Custom options
    if (typeof this.customOpt === 'object' && Object.keys(this.customOpt).length > 0) {
      this.options = { ...this.options, ...this.customOpt }
    }
  },
  async beforeMount () {
    await this.$store.dispatch('lockedEntities/initLockedEntities')
  },
  beforeDestroy () {
    this.$store.commit(`__${this.resource}/setOptions`, {
      page: 1,
      query: null,
      status: null,
      sortBy: [],
      sortDesc: []
    })

    // this.$store.commit(`__${this.resource}/clearList`)
    if (typeof window === 'undefined') {
      return false
    }
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  destroyed () {
    this.$eventBus.$off(`${this.resource}_updateItem`)
    this.$eventBus.$off(`${this.resource}_removeItem`)

    this.$eventBus.$off(`${this.resource}_reloadData`)
    this.$eventBus.$off(`${this.resource}_updateRecord`)
    this.$eventBus.$off(`${this.resource}_updateEditedItem`)
    this.$eventBus.$off(`${this.resource}_updateLockedState`)
  },
  methods: {
    addDefaultTableHeaders() {
      this.beforeHeaders = []

      // Default table columns
      if (this.defaultTableHeaders.indexOf('createdAt') > -1) {
        this.afterHeaders.push({
          text: this.$t('tableData.creationDate'),
          align: 'center',
          value: 'createdAt',
          id: 'createdAt',
          width: '125px'
        })
      }
      if (this.defaultTableHeaders.indexOf('actions') > -1) {
        this.afterHeaders.push({
          text: this.$t('tableData.actions'),
          align: 'center',
          value: 'actions',
          sortable: false,
          width: this.actionsDisplayMethod === 'list' ? '80px' : '130px'
        })
      }
    },
    async initLockedEntities (resource) {
      if (resource === undefined) {
        console.log(`Resource name not found, initialization not possible (Error in ${this.resource})`)
        resource = this.resource
      }
      const lockedEntities = this.getLockedEntities
      const items = this.$store.getters[`__${resource}/items`]
      if (lockedEntities instanceof Array && lockedEntities.length > 0) {
        lockedEntities.map((le) => {
          const lockedItem = items.find((i) => i._id === le)
          if (lockedItem) {
            this.selectedHandle({ item: lockedItem, value: false })
            this.$store.commit(`__${resource}/lockItem`, lockedItem)
          }
        })
      } else {
        items.map((i) => {
          if (i.locked === true) {
            this.selectedHandle({ item: i, value: false })
            this.$store.commit(`__${resource}/unlockItem`, i)
          }
        })
      }
    },
    selectedHandle ({ item, value }) {
      this.$store.commit(`__${this.resource}/setCheckedItems`, { item, value })
      return false
    },
    selectAllHandle (props) {
      if (this.selected.length !== this.items.length - this.disabledCount) {
        this.selected = []
        const self = this
        props.items.forEach(item => {
          if (!item.disabled) {
            self.selected.push(item)
            self.$store.commit(`__${self.resource}/setCheckedItems`, { item, value: true })
          }
        })
      } else {
        this.selected = []
        this.$store.commit(`__${this.resource}/clearCheckedItems`)
      }

      // this.selected = value === true ? items : []
      // const self = this
      // setTimeout(() => {
      //   if (value === true) {
      //     self.selected.map((item) => self.$store.commit(`__${self.resource}/setCheckedItems`, { item, value: true }))
      //   } else {
      //     self.$store.commit(`__${self.resource}/clearCheckedItems`)
      //   }
      // }, 300)
      // // this.selected = this.selected.length ? [] : this.items.slice()
      // // eslint-disable-next-line no-unused-expressions
      // // items.length > 0
      // //   ? items.map((item) => this.$store.commit(`__${this.resource}/setCheckedItems`, { item, value }))
      // //   : []
    },
    async load () {
      await this.$nextTick(async () => {
        window.scroll({ top: 0, behavior: 'smooth' })
        await this.$store.dispatch(`__${this.resource}/list`)
        await this.initLockedEntities(this.resource)
      })
    },
    createItem () {
      this.defaultItem.owner_id = this.$store.getters['auth/loggedInUser'].id
      Object.assign(this.defaultItem, this.formDefaultValues)
      this.editedItem = Object.assign({}, this.defaultItem)
      this.$store.commit(`__${this.resource}/setEditedItem`, this.editedItem)
      this.dialog = true
    },
    updateItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.$store.commit(`__${this.resource}/setEditedItem`, this.editedItem)
      this.dialog = true
    },
    removeItem (item) {
      this.editedItem = Object.assign({}, item)
      this.$store.commit(`__${this.resource}/setEditedItem`, this.editedItem)
      this.dialogRemove = true
    },
    async removeItemConfirm () {
      try {
        await this.$store.dispatch(`__${this.resource}/remove`, this.bulkRemoving === true ? this.selected : this.editedItem)
        this.closeRemove()
      } catch (e) {
        await this.$toast.error(e.response.data.message || e.message)
      }
    },
    removeSelectedItems () {
      this.dialogRemove = true
      this.bulkRemoving = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$store.commit(`__${this.resource}/setEditedItem`, {})
      })
    },
    closeRemove () {
      this.dialogRemove = false
      if (this.bulkRemoving === true) {
        this.bulkRemoving = false
        this.selected = []
        this.$store.commit(`__${this.resource}/clearCheckedItems`)
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        // this.$store.commit(`__${this.resource}/setEditedItem`, {})
      })
    },
    async save (e) {
      try {
        const v = e.$refs.form.validate()
        if (v) {
          this.saveLoading = true
          if (this.editedIndex > -1) {
            await this.$store.dispatch(`__${this.resource}/update`, this.prepareItemData())
            await this.$toast.success(this.$t('tableData.updatedItemSuccessMessage'))
          } else {
            await this.$store.dispatch(`__${this.resource}/create`, this.prepareItemData())
            await this.$toast.success(this.$t('tableData.createdItemSuccessMessage'))
          }
          this.close()
        }
      } catch (e) {
        await this.$toast.error(e.response.data.message || e.message)
      } finally {
        this.saveLoading = false
      }
    },
    prepareItemData () {
      const allowedFields = [...['id'], ...Object.keys(this.formScheme)]
      const editedItemData = {}
      Object.keys(this.getEditedItem).map((itemFieldKey) => {
        if (allowedFields.indexOf(itemFieldKey) > -1) {
          editedItemData[itemFieldKey] = this.getEditedItem[itemFieldKey]
        }
        return itemFieldKey
      })
      return editedItemData
    },
    changeEditedItem (event) {
      this.editedItem = event
      this.$emit('inputChange', this.editedItem)
    },
    dialogListener (val) {
      this.$emit('dialogChange', { dialog: val, item: this.editedItem })
    },
    // onResize () {
    //   this.needCompacted = window.innerWidth < 960
    //   this.tableHeight = window.innerHeight - 250
    // },
    focusSearchInput () {
      this.$refs.tableHeaderSearch.focus()
    },
    showSearchForm () {
      this.showSearchField = !this.showSearchField
      this.$nextTick(() => {
        this.focusSearchInput()
      })
    },
    async goTo (e) {
      await this.$vuetify.goTo(
        '.data-table tbody tr',
        {
          offset: 0,
          container: '.v-data-table__wrapper',
          duration: 1000
        }
      )
    }
  }
}
</script>

<style lang="scss">
.v-data-table__wrapper td {
  max-width: 500px;
}

.v-data-table-header-mobile {
  display: none;
}

.search {
  max-width: 250px !important;
  margin-right: 15px;
}

.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: #EEE;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12)
}

.v-data-table--fixed-header .v-data-footer {
  margin-right: 0 !important;
}

.v-menu__content {
  min-width: 80px !important;
}

.drop-menu-container .d-inline-flex {
  width: 100%;
}

.actions-cell {
  white-space: nowrap;
  text-align: right;
}

.v-data-table-header th {
  white-space: nowrap;
}

.table-data-header {
  font-weight: normal;
}

.table-data-header-text {
  color: #2A2A2ADD
}
</style>
