import axios from 'axios'
import paths from '../../paths'

const methods = {}

/**
 * Get "All" Proxies List (limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getProxiesList = async () => {
  return axios.get(paths.proxies.all)
}

methods.getProxyCredentials = async (id) => {
  return axios.post(paths.proxies.auth.replace(':id', id))
}

methods.checkProxyStatus = async (id) => {
  return axios.get(paths.proxies.status.replace(':id', id))
}

methods.cloneProxy = async (id) => {
  return axios.post(paths.proxies.clone.replace(':id', id), {})
}

export default methods
