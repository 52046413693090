import Vue from 'vue'
import tasksUploadService from '@/services/api/tasks/upload.service.js'

const getDefaultState = (def = {}) => ({
  ...{
    files: [],
    showProgress: false
  },
  ...def
})

export default {
  namespaced: true,
  name: 'tasksUpload',
  state: getDefaultState(),
  getters: {
    getFiles: (state) => state.files,
    isProgressShow: (state) => state.showProgress
  },
  actions: {
    async commonUpload({ dispatch, commit }, { f }) {
      await tasksUploadService.commonUpload(f, (event, e2) => {
        const progress = Math.round((100 * event.loaded) / event.total)
        commit('tasksUpload/setProgress', { f, progress }, { root: true })
      }).catch((e) => {
        Vue.prototype.$toast.error(e.response.data.message)
      })
      await commit('tasksUpload/removeFile', f, { root: true })
      await dispatch('__tasks.upload/list', null, { root: true })
    }
  },
  mutations: {
    setFiles(state, files) {
      state.files = files
    },
    setShowProgress(state, show) {
      state.showProgress = show === true
    },
    setProgress(state, { f, progress }) {
      if (state.files.indexOf(f) < 0) {
        return false
      }
      const file = state.files[state.files.indexOf(f)]
      if (file === undefined) {
        return false
      }
      file.progress = progress
      Vue.set(state.files, state.files.indexOf(f), file)
    },
    removeFile(state, f) {
      if (state.files.indexOf(f) < 0) {
        return false
      }
      state.files.splice(state.files.indexOf(f), 1)
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
