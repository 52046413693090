// import Vue from 'vue'
// import store from '@/store'
import tasksUnpackingService from '@/services/api/tasks/unpacking.service.js'

const getDefaultState = (def = {}) => ({
  ...{
    unpackingResult: [],
    unpackingLog: []
  },
  ...def
})

export default {
  namespaced: true,
  name: 'tasksUnpacking',
  state: getDefaultState(),
  getters: {
    getUnpackingResult: (state) => state.unpackingResult,
    getUnpackingLog: (state) => state.unpackingLog
  },
  actions: {
    /**
     * Unpacking archive
     * @param dispatch
     * @param commit
     * @param parentId
     * @param data
     * @returns {Promise<AxiosResponse<any>|any>}
     */
    async unpacking({ dispatch, commit }, { parentId, data }) {
      const response = await tasksUnpackingService.unpacking(parentId, data)
      if (response.status === 200) {
        commit('tasksUnpacking/setUnpackingResult', { parentId, result: response.data }, { root: true })
        return response.data
      }
      return response
    },
    /**
     * Get Unpacking Log
     * @param dispatch
     * @param commit
     * @param parentId
     * @returns {Promise<AxiosResponse<any>|any>}
     */
    async getUnpackingLog({ dispatch, commit }, { parentId }) {
      const response = await tasksUnpackingService.getUnpackingLog(parentId)
      if (response.status === 200) {
        commit('tasksUnpacking/setUnpackingLog', { parentId, log: response.data }, { root: true })
        return response.data
      }
      return response
    }
  },
  mutations: {
    setUnpackingResult(state, { parentId, result }) {
      if (state.unpackingResult[parentId] === undefined) {
        state.unpackingResult[parentId] = {}
      }
      state.unpackingResult[parentId] = result
    },
    setUnpackingLog(state, { parentId, log }) {
      if (state.unpackingLog[parentId] === undefined) {
        state.unpackingLog[parentId] = {}
      }
      state.unpackingLog[parentId] = log
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
