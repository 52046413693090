import packageJson from '../../../package.json'

const getDefaultState = (def = {}) => ({
  ...{
    appTitle: 'WebTools',
    appVersion: packageJson.version,
    appHotUpdate: 0
  },
  ...def
})

const getters = {
  appTitle: (state) => state.appTitle,
  appVersion: (state) => state.appVersion,
  appHotUpdate: (state) => state.appHotUpdate
}

const actions = {
  setAppVersion({ commit }, payload) {
    commit('SET_APP_VERSION', payload)
  }
}

const mutations = {
  SET_APP_VERSION(state, version) {
    state.appVersion = version
  },
  INCR_HOT_UPDATE(state) {
    state.appHotUpdate += 1
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  name: 'app',
  state: getDefaultState(),
  getters,
  actions,
  mutations
}
