import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Create domain
 * @param {String} id
 * @param {String} name
 * @returns {AxiosPromise<any>}
 */
methods.domainAdd = async (data) => {
  return axios.post(paths.domains.management.create, data)
}

/**
 * Get Domains By Cloudflare Id
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getDomainsByCloudflareId = async (id) => {
  return axios.post(paths.domains.management.cf, { id })
}

/**
 * Get Domain By Name
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getDomainByName = async (name) => {
  return axios.get(`${paths.domains.management.list}?query=${encodeURIComponent(name)}`)
}

/**
 * Bulk Editing
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
methods.bulkEditing = async (data) => {
  return axios.patch(paths.domains.management.bulk.edit, data)
}

/**
 * Sync Domain Dns info
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
methods.syncDomainDns = async (id) => {
  return axios.post(paths.domains.management.sync.replace(':id', id))
}

methods.getDomainsCountsByStatuses = async () => {
  return axios.get(paths.domains.management.counts)
}

/**
 * Get Domain Full Data By Name
 * @param {String} name
 * @returns {AxiosPromise<any>}
 */
methods.getDomainFullData = async (name) => {
  return axios.post(paths.domains.management.fullData, { name })
}

/**
 *
 * @param name
 * @returns {AxiosPromise<any>}
 */
methods.removeAllDraft = async () => {
  return axios.delete(paths.domains.management.removeAllDraft)
}

/**
 * Bulk Editing
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
methods.undelete = async (data) => {
  return axios.patch(paths.domains.management.undelete, data)
}

/**
 * Search domains with cp
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
methods.searchDomainsWithCp = async (data) => {
  return axios.post(paths.domains.management.withCp, data)
}
/**
 * Check allowed domains
 * @param {Object} data
 * @returns {AxiosPromise<any>}
 */
methods.checkAllowedDomains = async (data) => {
  return axios.post(paths.domains.management.checkAllowedDomains, data)
}

export default methods
