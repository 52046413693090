import Vue from 'vue'
import store from '../store'

Vue.directive('can', {
  bind: (el, binding, vnode) => {
    if (binding.value !== undefined) {
      // if (store.getters.loggedInUser.role === process.env.VUE_APP_MAIN_ROLE) {
      //   return
      // }
      if (!store.getters['auth/loggedInUser'].roleRights.includes(binding.value)) {
        vnode.elm.style.display = 'none'
      }
    }
  }
})

Vue.prototype.$can = (action, entity = null) => {
  if (store.getters['auth/isAuthenticated'] !== true) {
    return false
  }
  // if (store.getters.loggedInUser.role === process.env.VUE_APP_MAIN_ROLE) {
  //   return true
  // }
  if (entity === null) {
    return !!store.getters['auth/loggedInUser'].roleRights.includes(action)
  } else {
    return !!(
      store.getters['auth/loggedInUser'].roleRights.includes(action) &&
      (
        store.getters['auth/loggedInUser'].id === entity.owner_id ||
        store.getters['auth/loggedInUser'].affiliate_team_id === entity.affiliate_team_id
      )
    )
  }
}
