// import Vue from 'vue'
// import store from '@/store'
import tasksLPGService from '@/services/api/tasks/lpg.service.js'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'tasksLPG',
  state: getDefaultState(),
  getters: {

  },
  actions: {
    async generate({ dispatch, commit }, { data }) {
      const response = await tasksLPGService.generate(data)
      if (response.status === 201) {
        return response.data
      }
      return response
    },
    async saveConfig({ dispatch, commit }, { data }) {
      const saveConfigResponse = await tasksLPGService.saveConfig(data)
      const { status, data: responseData } = saveConfigResponse
      const { response } = responseData
      if (status === 200) {
        if (response.success === true) {
          await commit('__tasks.lpg/saveItem', response.result, { root: true })
        }
      }
      return response
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
