import usersService from '@/services/api/users/users.service'

const getDefaultState = (def = {}) => ({
  ...{
    qr: '',
    secret: {}
  },
  ...def
})

export default {
  namespaced: true,
  name: 'users',
  state: getDefaultState(),
  getters: {},
  actions: {
    async getAllUsers() {
      try {
        const response = await usersService.getAllUsers()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const user = response.data[key]
            data.push({ text: user.name, value: user.id })
          })
          return data
        }
      } catch (e) {
        // e
      }
    },
    async saveUserPermissions(context, payload) {
      const { userId, permissions } = payload
      const response = await usersService.updateUserPermissions(userId, permissions)
      if (response.status === 200) {
        await context.commit('__users.management/saveItem', response.data, { root: true })
      }
    },
    async enable2FAStep1(context) {
      const response = await usersService.enable2FAStep1()
      if (response.status === 200) {
        const { qr, secret } = response.data
        context.state.qr = qr
        context.state.secret = secret
        return response.data
      }
      return response
    },
    async enable2FAStep2(context, data) {
      const response = await usersService.enable2FAStep2(data)
      if (response.status === 200) {
        await context.dispatch('auth/fetchUser', {}, { root: true })
        return response.data
      }
      return response
    },
    async disable2FA(context, data) {
      const response = await usersService.disable2FA(data)
      if (response.status === 204) {
        await context.dispatch('auth/fetchUser', {}, { root: true })
      }
      return response
    },
    async getOTPAuthQR(context, userId) {
      const response = await usersService.getOTPAuthQR(userId)
      const { status, data } = response
      if (status === 200) {
        return data
      }
      return response
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
