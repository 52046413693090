const methods = {}

let eventBus = {}

methods.setEventBus = (eb) => {
  eventBus = eb
}

methods.generatorTaskRefresh = (data) => {
  eventBus.$emit('generatorTaskRefresh', data)
}

methods.leftSidebarDomainsCounters = () => {
  eventBus.$emit('leftSidebarReloadDomainsCounters', {})
}

methods.reloadResourceTable = (resourceName) => {
  eventBus.$emit(`${resourceName}_reloadData`, {})
}

methods.updateRecordInResourceTable = (resourceName, data = {}) => {
  eventBus.$emit(`${resourceName}_updateRecord`, { resource: resourceName, data })
}

methods.updateLockedEntities = (data) => {
  eventBus.$emit(`${data.resource}_updateLockedState`, data)
}

methods.updateUnpacking = (data) => {
  eventBus.$emit('updateUnpacking', data)
}

methods.updateDashboardRequestsStats = (data) => {
  eventBus.$emit('updateDashboardRequestsStats', data)
}

methods.updateUserAuth = (data) => {
  eventBus.$emit('updateUserAuth', data)
}

methods.setUsersPermissionsByRole = (data) => {
  eventBus.$emit(`role.${data.role}`, data)
}

export default methods
