import axios from 'axios'
import paths from '../../paths'

/**
 * Get general counters
 * @param payload
 * @returns {axios<any>}
 */
const getGeneralCounters = async (payload) => {
  return axios.get(paths.dashboard.getGeneralCounters, payload)
}

/**
 * Get general counters
 * @param payload
 * @returns {axios<any>}
 */
const getMissingDataCounters = async (payload) => {
  return axios.get(paths.dashboard.getMissingDataCounters, payload)
}

/**
 * Get general counters
 * @param payload
 * @returns {axios<any>}
 */
const getRequestsStats = async (payload) => {
  return axios.get(paths.dashboard.getRequestsStats, payload)
}

export default {
  getGeneralCounters,
  getMissingDataCounters,
  getRequestsStats
}
