import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Get Domain By Name
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getTldsList = async () => {
  return axios.get(paths.domains.generator.tldsList)
}

/**
 * Get Domain By Name
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.quickGenerate = async (data) => {
  return axios.post(paths.domains.generator.quickGenerate, { ...data })
}

export default methods
