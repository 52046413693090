import eventLogService from '@/services/api/event-log.service'

const getDefaultState = (def = {}) => ({
  ...{
    eventLog: []
  },
  ...def
})

export default {
  namespaced: true,
  name: 'eventLog',
  state: getDefaultState(),
  getters: {
    getEventLog: state => state.eventLog
  },
  actions: {
    async getUserEventLog({ dispatch, commit }) {
      try {
        const response = await eventLogService.getEventLog()
        if (response.status === 200) {
          commit('setEventLog', response.data.result)
          return response.data.result
        }
      } catch (e) {
        // e
      }
    }
  },
  mutations: {
    setEventLog(state, data) {
      state.eventLog = data
    },
    addEvent(state, event) {
      state.eventLog.unshift(event)
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
