import hostingServersService from '@/services/api/hosting/servers.service'
import helper from '@/utils/helper'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'hostingServers',
  state: getDefaultState(),
  getters: {},
  actions: {
    async getHostingServersList() {
      try {
        const response = await hostingServersService.getHostingServersList()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const provider = response.data[key]
            data.push({ text: provider.name, value: provider.id })
          })
          return data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getHostingServersListByProviderId({ dispatch }, { id }) {
      try {
        const response = await hostingServersService.getHostingServersListByProviderId(id)
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const provider = response.data[key]
            data.push({
              id: provider._id,
              entity_id: provider._id,
              entity_type: 'hostingServer',
              name: provider.name,
              price: 0.0,
              nextPaymentDate: new Date().toISOString().substring(0, 10),
              notes: provider.notes,
              country: provider.country,
              ip: provider.ip
            })
          })
          return data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getVestaHostingServersList() {
      try {
        const response = await hostingServersService.getVestaHostingServersList()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const provider = response.data[key]
            data.push({ text: provider.name, value: provider.id })
          })
          return data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getHostingServerCredentials({ dispatch }, { id }) {
      try {
        const response = await hostingServersService.getHostingServerCredentials(id)
        if (response.status === 200) {
          const data = response.data
          return {
            vesta: {
              ip: data.ip[0],
              port: data.port,
              login: data.login,
              password: data.password
            },
            ssh: {
              ip: data.ip[0],
              port: data.ssh_port,
              login: data.root_login,
              password: data.root_password,
              link: 'sftp://' +
                `${data.root_login}` +
                `${data.root_password !== undefined ? `:${data.root_password}` : ''}` +
                '@' +
                `${data.ip[0]}:${data.ssh_port ? data.ssh_port : 22}`
            }
          }
        }
      } catch (e) {
        console.log(e)
        throw e
      }
      return false
    },
    async getHostingServerData({ dispatch }, { id }) {
      try {
        const response = await hostingServersService.getHostingServerData(id)
        if (response.status === 200) {
          const data = response.data
          return data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getHostingServerIp({ dispatch }, { id }) {
      try {
        const response = await hostingServersService.getHostingServerIp(id)
        if (response.status === 200) {
          const data = []
          Object.keys(response.data.result).forEach((key) => {
            data.push({ text: key, value: key })
          })
          return data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getHostingServerUsers({ dispatch }, { id }) {
      try {
        const response = await hostingServersService.getHostingServerUsers(id)
        if (response.status === 200) {
          if (helper.has(response.data, 'success') && response.data.success === true) {
            return response.data.result
          }
        }
      } catch (e) {
        console.log(e)
        throw e
      }
      return []
    },
    async importDomains({ dispatch, commit }, { id }) {
      try {
        const response = await hostingServersService.importDomains(id)
        /* if (response.status === 200) {
          if (helper.has(response.data, 'success') && response.data.success === true) {
            await commit('__hosting.servers/saveItem', response.data.result, { root: true })
          }
        } */
        return response.data
      } catch (e) {
        console.log(e)
        // throw e
      }
    },
    async saveDomain({ dispatch, commit }, { action, id, domain, ip, user }) {
      try {
        let response
        if (action === 'update') {
          response = await hostingServersService.updateDomain(id, domain, ip, user)
        } else {
          response = await hostingServersService.addDomain(id, domain, ip, user)
        }
        if (response.status === 200) {
          if (helper.has(response.data, 'success') && response.data.success === true) {
            await commit('__hosting.servers/saveItem', response.data.result, { root: true })
          }
        }
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getServerCpuStatus({ dispatch, commit }, { id }) {
      try {
        const response = await hostingServersService.getServerCpuStatus(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getServerMemoryStatus({ dispatch, commit }, { id }) {
      try {
        const response = await hostingServersService.getServerMemoryStatus(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getServerDiskStatus({ dispatch, commit }, { id }) {
      try {
        const response = await hostingServersService.getServerDiskStatus(id)
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async changeDomainOwner({ dispatch, commit }, { id, domain, newOwner }) {
      try {
        const response = await hostingServersService.changeDomainOwner(id, domain, newOwner)
        // if (response.status === 200) {
        //   if (helper.has(response.data, 'success') && response.data.success === true) {
        //     // await commit('__hosting.servers/saveItem', response.data.result, { root: true })
        //   }
        // }
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async domainTransfer({ dispatch, commit }, data) {
      try {
        const response = await hostingServersService.domainTransfer(data)
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async restartWeb({ dispatch, commit }, id) {
      try {
        const response = await hostingServersService.restartWeb(id)
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async domainSync({ dispatch, commit }, { id, domain }) {
      try {
        const response = await hostingServersService.domainSync(id, domain)
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async domainRemove({ dispatch, commit }, { id, domain, user }) {
      try {
        const response = await hostingServersService.removeDomain(id, domain, user)
        return response.data
      } catch (e) {
        console.log(e)
        throw e
      }
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
