import Storage from '@/libs/storage/Storage'
import i18n from '@/plugins/i18n'

const getDefaultState = (def = {}) => ({
  ...{
    locale: Storage.getItem('locale')
  },
  ...def
})

const getters = {
  locale: (state) => state.locale
}

const actions = {
  /**
   * Set locale
   * @param {Function} commit
   * @param {String} payload
   */
  async setLocale({ commit }, payload) {
    if (i18n.availableLocales.indexOf(payload) >= 0) {
      i18n.locale = payload
      Storage.setItem('locale', payload)
      commit('SET_LOCALE', payload)
    }
  }
}

const mutations = {
  /**
   * Set locale mutation
   * @param state
   * @param value
   */
  SET_LOCALE(state, value) {
    state.locale = value
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  name: 'locale',
  state: getDefaultState(),
  getters,
  actions,
  mutations
}
