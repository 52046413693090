import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createWebSocketPlugin from './plugins/websocketStorePlugin'
import socket from '../plugins/socket'

// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

const websocketPlugin = createWebSocketPlugin(socket)

const store = new Vuex.Store({
  // strict: debug,
  state: {},
  mutations: {},
  actions: {
    resetStates ({ commit }) {
      // console.log(store._modules.root._children)
      // console.log(modules)

      Object.keys(store._modules.root._children).forEach((namespace) => {
        commit(`${namespace}/resetState`)
      })
    }
  },
  plugins: [websocketPlugin],
  ...{ modules }
})

export default store
