import hostingProvidersService from '@/services/api/hosting/providers.service'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'hostingProviders',
  state: getDefaultState(),
  getters: {},
  actions: {
    async getHostingProvidersList() {
      try {
        const response = await hostingProvidersService.getHostingProvidersList()
        if (response.status === 200) {
          const data = []
          Object.keys(response.data).forEach((key) => {
            const provider = response.data[key]
            data.push({ text: provider.name, value: provider.id })
          })
          return data
        }
      } catch (e) {
        // e
      }
    },
    async getHostingProviderCredential({ dispatch }, { id }) {
      try {
        const response = await hostingProvidersService.getHostingProviderCredential(id)
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        // e
      }
      return false
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
