<template>
  <div>
    <v-dialog
      v-model="dialog.show"
      scrollable
      :max-width="`${dialog.maxWidth}px`"
      :hide-overlay="dialog.hideOverlay"
      :persistent="dialog.persistent"
      :no-click-animation="dialog.noClickAnimation"
      :retain-focus="false"
      :fullscreen="fullscreen"
      transition="fab-transition"
      @click:outside.stop="closeDialog"
    >
      <v-card
        :max-height="`${ Number.isInteger(dialog.maxHeight) ? `${dialog.maxHeight}px` : 'auto' }`"
        :class="`dialog-container ${dialog.transparent === true ? 'dialog-transparent' : ''}`"
      >
        <v-card-title class="text-h5">
          {{ dialog.title }}
          <v-spacer />
          <v-btn
            icon
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="dialog.contentType === 'code'">
            <pre :class="`code ${allowScroll ? 'code-scroll' : ''}`">{{ dialog.content }}</pre>
          </div>
          <div v-else-if="dialog.contentType === 'component'">
            <component
              :is="dialog.content"
              :data="dialog.data"
              @close="close"
            />
          </div>
          <div v-else>
            {{ dialog.content }}
          </div>
        </v-card-text>
        <v-card-actions v-if="dialog.withActionButtons">
          <v-col class="pa-0 checkbox-text">
            <v-checkbox
              v-if="dialog.contentType === 'code'"
              v-model="allowScroll"
              :label="$t('dialog.showScroll')"
              value="true"
            />
          </v-col>
          <v-col class="text-right pb-0">
            <v-btn
              color="blue darken-1"
              text
              class="align-content-end close-btn"
              @click="fullscreen = !fullscreen"
            >
              Full-screen
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              class="align-content-end close-btn"
              @click="close()"
            >
              {{ $t('dialog.close') }}
            </v-btn>
          </v-col>
        </v-card-actions>
        <v-card-actions v-else />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogContainer',
  data () {
    return {
      allowScroll: false,
      fullscreen: false
    }
  },
  computed: {
    dialog () {
      return this.$store.getters['dialog/getDialog']
    }
  },
  methods: {
    close () {
      this.$store.dispatch('dialog/dialog', {
        show: false,
        persistent: false,
        title: '',
        contentType: 'text',
        content: '',
        maxWidth: 800
        // maxHeight: 500
      })
    },
    closeDialog(e) {

    }
  }
}
</script>

<style lang="scss">
.dialog-container {
  .v-input--selection-controls {
    margin-top: 0;
  }
  .close-btn {
    margin-top: -15px;
  }
  .code {
    font-family: monospace !important;
    background-color: black;
    color: #e39a0f;
    padding: 7px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
  .theme--light.v-label {
    color: #ccc ;
  }
  .code-scroll {
    overflow-x: scroll;
  }
}
.dialog-transparent {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";

  /* IE 5-7 */
  filter: alpha(opacity=70);

  /* Netscape */
  -moz-opacity: 0.75;

  /* Safari 1.x */
  -khtml-opacity: 0.75;

  /* Good browsers */
  opacity: 0.75;
}
.dialog-transparent:hover {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  /* IE 5-7 */
  filter: alpha(opacity=100);

  /* Netscape */
  -moz-opacity: 1;

  /* Safari 1.x */
  -khtml-opacity: 1;

  /* Good browsers */
  opacity: 1;
}
</style>
