import axios from 'axios'
import Vue from 'vue'

import { cyr2latAlphabet } from './transliteration'

const methods = {}

/**
 * Compare Two Arrays
 * @param {Array} array1
 * @param {Array} array2
 * @returns {boolean}
 */
methods.compareTwoArrays = (array1, array2) => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false
  }
  const array2Sorted = array2.slice().sort()
  return (
    array1.length > 0 &&
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every((value, index) => {
        return value === array2Sorted[index]
      })
  )
}

/**
 * Dots To Object
 * @param {String} path
 * @param {Object} paths
 * @returns {*}
 */
methods.dotsToObject = (path, paths) => {
  const pathIndex = (obj, i) => obj[i]
  return path.split('.').reduce(pathIndex, paths)
}

/**
 * Add Element To Object
 * @param {Object} obj
 * @param {String} key
 * @param {Object} value
 * @param {string} beforeKey
 * @returns {{}}
 */
methods.addToObject = (obj, key, value, beforeKey) => {
  const index = Object.keys(obj).indexOf(beforeKey)
  const temp = {}
  let i = 0
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      if (i === index && key && value) {
        temp[key] = value
      }
      temp[prop] = obj[prop]
      i++
    }
  }
  if (index < 0 && key && value) {
    temp[key] = value
  }
  return temp
}

/**
 * Add Element To Array
 * @param {Array} arr
 * @param {{text: VueI18n.TranslateResult, value: string}} value
 * @param {Number} index
 * @returns {*}
 */
methods.addToArray = (arr, value, index) => {
  arr.splice(index, 0, value)
  return arr
}

/**
 * Convert String To Slug
 * @param {String} str
 * @param {String} space
 * @returns {string}
 */
methods.convertToSlug = (str, space = '-') => {
  return str
    .toLowerCase()
    .replace(/[^\w\s-\\.]+/g, '')
    .replace(/ +/g, space)
}

/**
 * Open URL In New Window
 * @param domain
 */
methods.openWindow = (domain) => {
  window.open(`https://${domain}`, '_blank')
}

/**
 * DateTime Filter
 * @param {String} date
 * @param {Boolean} timeOnly
 * @returns {string|string|*}
 */
methods.dateTimeFilter = (date, timeOnly = false) => {
  if (!date) {
    return ''
  }
  if (date.indexOf('T')) {
    const dateArray = date.split('T')
    if (timeOnly === true) {
      return dateArray.length === 2 ? `${dateArray[1].substring(0, 8)}` : ''
    }
    return dateArray.length === 2
      ? `${dateArray[0]} ${dateArray[1].substring(0, 8)}`
      : ''
  }
  return date
}

/**
 * Date Filter
 * @param date
 * @returns {string|string|*}
 */
methods.dateFilter = (date) => {
  if (!date) {
    return ''
  }
  if (date.indexOf('T')) {
    const dateArray = date.split('T')
    return dateArray.length > 0 ? `${dateArray[0]}` : ''
  }
  return date
}

methods.timeFilter = (date) => {
  if (!date) {
    return '-:-:-'
  }
  let inputDate = new Date(date)
  inputDate = inputDate.toISOString()
  if (!inputDate) {
    return ''
  }
  if (inputDate.indexOf('T')) {
    const dateArray = inputDate.split('T')
    return dateArray.length === 2 ? `${dateArray[1].substring(0, 8)}` : ''
  }
  return inputDate
}

/**
 * Object Has Parameter
 * @param {Object} object
 * @param {String} key
 * @returns {boolean}
 */
methods.has = (object, key) => {
  if (typeof object === 'undefined') {
    return false
  }
  return Object.prototype.hasOwnProperty.call(object, key)
}

/**
 * Get Nested Object
 * @param {Array} p
 * @param {Object} o
 * @return {*}
 */
methods.getNestedObj = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)

/**
 * Recursive search in object by object id
 * @param {String} id
 * @param {Object} currentNode
 * @return {*|boolean|boolean}
 */
methods.findNode = (id, currentNode) => {
  let i, currentChild, result
  if (id === currentNode.id) {
    return currentNode
  } else {
    if (typeof currentNode.children === 'undefined') {
      return false
    }
    for (i = 0; i < currentNode.children.length; i += 1) {
      currentChild = currentNode.children[i]
      result = methods.findNode(id, currentChild)
      if (result !== false) {
        return result
      }
    }
    return false
  }
}

/**
 * Is Cloudflare NS
 * @param {String|Array} host
 * @return {boolean}
 */
methods.isCloudflareNS = (host) => {
  if (typeof host === 'undefined') {
    return false
  }
  const hostArray = Array.isArray(host) ? host : [host]
  return (
    typeof hostArray[0] === 'string' &&
    hostArray[0].indexOf('ns.cloudflare.com') > -1
  )
}

/**
 * Capitalize the first letter of the string
 * @param {String} string
 * @return {string}
 */
methods.capFirstLetter = (string) => {
  if (typeof string === 'undefined') {
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Is Win OS
 * @returns {boolean|undefined}
 */
methods.isWin = () => {
  try {
    return window.navigator.platform.toLowerCase().includes('win')
  } catch (e) {
    console.log(e)
  }
  return undefined
}

/**
 * Is JSON data
 * @param {Any|String} dataRaw
 * @returns {any}
 */
methods.isJSON = (dataRaw) => {
  try {
    return JSON.parse(dataRaw)
  } catch (e) {
    return dataRaw
  }
}

/**
 * String conversion for use in URL
 * @param {String} str
 * @returns {string}
 */
methods.slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

/**
 * Flip Object
 * @param {Object} obj
 * @returns {{}}
 */
methods.flipObj = (obj) => {
  const ret = {}
  for (const key in obj) {
    ret[obj[key]] = key
  }
  return ret
}

/**
 * Transliterate (Converting text to letters of another alphabet)
 * @param {String} word
 * @param {String} mode
 * @returns {*}
 */
methods.transliterate = (word, mode = 'c2l') => {
  let alphabet
  switch (mode) {
    case 'l2c':
      alphabet = methods.flipObj(cyr2latAlphabet)
      break
    default:
      alphabet = cyr2latAlphabet
      break
  }
  return word
    .split('')
    .map((char) => {
      return alphabet[char] || char
    })
    .join('')
}

methods.download = async (url) => {
  const filename = new URL(url).pathname.split('/').pop()
  const response = await axios.get(url, { responseType: 'arraybuffer' })
  const blob = new Blob([response.data], { type: 'application/gzip' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()

  await Vue.prototype.$toast.success('Archive downloaded successfully')
}

methods.objectSort = (items, property, direction, type = null) => {
  const prepareData = (str) => {
    switch (type) {
      case 'date':
        return new Date(str)
      case 'number':
        return parseInt(str, 10)
      default:
        return str.toString().toUpperCase()
    }
  }

  const compare = (a, b) => {
    if (!a[property] && !b[property]) {
      return 0
    }
    if (a[property] && !b[property]) {
      return -1
    }
    if (!a[property] && b[property]) {
      return 1
    }

    const value1 = prepareData(a[property], type)
    const value2 = prepareData(b[property], type)

    if (type === 'date' || type === 'number') {
      return direction === 'asc' ? value1 - value2 : value2 - value1
    }

    if (value1 < value2) {
      return direction === 'asc' ? -1 : 1
    }
    if (value1 > value2) {
      return direction === 'desc' ? 1 : -1
    }
    return 0
  }

  return items.sort(compare)
}

/**
 * Deep merge object
 * @param {Object} targetObject
 * @param {Object} sourceObject
 * @return {any}
 */
methods.deepMergeObject = (targetObject = {}, sourceObject = {}) => {
  const copyTargetObject = JSON.parse(JSON.stringify(targetObject))
  const copySourceObject = JSON.parse(JSON.stringify(sourceObject))
  Object.keys(copySourceObject).forEach((key) => {
    if (typeof copySourceObject[key] === 'object' && !Array.isArray(copySourceObject[key])) {
      copyTargetObject[key] = methods.deepMergeObject(
        copyTargetObject[key],
        copySourceObject[key]
      )
    } else {
      copyTargetObject[key] = copySourceObject[key]
    }
  })
  return copyTargetObject
}

export default methods
