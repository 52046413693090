<template>
  <div class="table-element-date">
    <div v-if="mode === 'tooltip'">
      <v-tooltip
        top
        color="black"
      >
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >{{ getDateArray[0] || '&mdash;' }}</span>
        </template>
        <span>{{ getDateArray[0] || '&mdash;' }} {{ getDateArray[1] || '&mdash;' }}</span>
      </v-tooltip>
    </div>
    <div v-else>
      <div class="date">
        {{ getDateArray[0] || '&mdash;' }}
      </div>
      <div
        v-if="getDateArray[1] !== '00:00:00'"
        class="time"
      >
        {{ getDateArray[1] || '&mdash;' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateElement',
  props: {
    mode: {
      type: String,
      default: 'base'
    },
    date: {
      type: [String, Object, Date],
      default: '0000-00-00'
    }
  },
  data () {
    return {
      dateArray: [

      ]
    }
  },
  computed: {
    getDateArray () {
      return this.dateArray
    }
  },
  watch: {
    date (val) {
      this.dateArray = this.parseDate(this.date)
    }
  },
  mounted () {
    this.dateArray = this.parseDate(this.date)
  },
  methods: {
    parseDate (date) {
      try {
        if (!date || date === undefined) {
          return ''
        }
        if (date.indexOf('T')) {
          const dateArray = date.split('T')
          return dateArray.length === 2
            ? [dateArray[0], dateArray[1].substring(0, 8)]
            : ''
        }
      } catch (e) {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.table-element-date .date {

}
.table-element-date .time {
  font-size: 10px !important;
  color: #949da7;
}
</style>
