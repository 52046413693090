<template>
  <v-tooltip
    v-if="tooltip"
    :top="tooltipTop"
    :bottom="tooltipBottom"
    :left="tooltipLeft"
    :right="tooltipRight"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="isHas(has)"
        v-can="can"
        :loading="loading"
        :disabled="disabled"
        :color="color"
        :class="textBtn === true ? 'text-btn' : ''"
        v-bind="attrs"
        :text="textBtn"
        :icon="iconBtn"
        v-on="on"
        @click="typeof fn === 'function' ? fn(currentItem, $event) : () => {}"
      >
        <v-icon :small="small">
          mdi-{{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
  <v-btn
    v-else
    v-show="isHas(has)"
    v-can="can"
    :loading="loading"
    :disabled="disabled"
    :color="color"
    :class="textBtn === true ? 'text-btn' : ''"
    :text="textBtn"
    :icon="iconBtn"
    @click="typeof fn === 'function' ? fn(currentItem, $event) : () => {}"
  >
    <v-icon :small="small">
      mdi-{{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    tooltip: {
      type: Boolean,
      default: true
    },
    tooltipText: {
      type: String,
      default: ''
    },
    tooltipTop: {
      type: Boolean,
      default: true
    },
    tooltipBottom: {
      type: Boolean,
      default: false
    },
    tooltipLeft: {
      type: Boolean,
      default: false
    },
    tooltipRight: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    textBtn: {
      type: Boolean,
      default: true
    },
    iconBtn: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'dots-horizontal'
    },
    small: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    has: {
      type: String,
      default: undefined
    },
    can: {
      type: String,
      default: undefined
    },
    fn: {
      type: Function,
      default: () => {
      }
    },
    event: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    currentItem() {
      return this.item
    },
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    }
  },
  mounted () {},
  methods: {
    isHas(propName) {
      if (typeof propName === 'string' && propName !== null) {
        return Object.prototype.hasOwnProperty.call(this.item, propName) && this.item[propName] !== null
      }
      return true
    },
    isLocked() {

    }
  }
}
</script>

<style lang="scss">
.text-btn {
  height: 30px !important;
  min-width: 30px !important;
  padding: 0 7px !important;
  color: #0000008a !important;
}
</style>
