import axios from 'axios'
import paths from '../../../paths'

const methods = {}

methods.unpacking = async (parentId, data) => {
  return axios.post(paths.tasks.unpacking.create.replace(':parentId', parentId), data)
}

methods.getUnpackingLog = async (parentId) => {
  return axios.get(paths.tasks.unpacking.list.replace(':parentId', parentId))
}

export default methods
