const getDefaultState = (def = {}) => ({
  ...{
    dialog: {
      show: false,
      title: '',
      contentType: 'text',
      content: '',
      data: {},
      maxWidth: 500,
      maxHeight: null,
      hideOverlay: false,
      persistent: false,
      noClickAnimation: false,
      withActionButtons: false,
      transparent: false
    }
  },
  ...def
})

const getters = {
  getDialog: state => state.dialog
}

const actions = {
  dialog({ commit }, payload) {
    commit('SET_DIALOG', payload)
  }
}

const mutations = {
  SET_DIALOG(state, payload) {
    state.dialog.show = payload.show || false
    state.dialog.title = payload.title || ''
    state.dialog.contentType = payload.contentType || 'text'
    state.dialog.content = payload.content || ''
    state.dialog.data = payload.data || {}
    state.dialog.maxWidth = payload.maxWidth || ''
    state.dialog.maxHeight = payload.maxHeight || ''
    state.dialog.hideOverlay = payload.hideOverlay || false
    state.dialog.persistent = payload.persistent || false
    state.dialog.noClickAnimation = payload.noClickAnimation || false
    state.dialog.withActionButtons = payload.withActionButtons
    state.dialog.transparent = payload.transparent
  },
  /**
   * Reset state
   * @param state
   */
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  name: 'dialog',
  state: getDefaultState(),
  getters,
  actions,
  mutations
}
