import settingsService from '@/services/api/settings.service'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'settings',
  state: getDefaultState(),
  getters: () => {
  },
  actions: {
    async restoreSettings({ dispatch }) {
      try {
        const response = await settingsService.restoreSettings()
        if (response.status === 200) {
          await dispatch('__settings/list', null, { root: true })
        }
      } catch (e) {
        throw new Error(e.response.data.message)
      }
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
