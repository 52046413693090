import Vue from 'vue'
import helper from '../../utils/helper'

const timeouts = []

export default function createWebSocketPlugin(socket) {
  return store => {
    store.$socket = socket

    /* Processing all sockets */
    socket.onAny((event, data) => {
      switch (event) {
        case 'initAppState':
          store.commit('socket/initAppState', data)
          break
        case 'appState':
          store.commit('socket/appState', data)
          break
        default:
          // console.log(data)
          break
      }
    })

    /* Processing message's */
    socket.on('message', async (dataRaw) => {
      const data = helper.isJSON(dataRaw)
      if (data.text) {
        switch (data.type) {
          case 'message':
            await Vue.prototype.$toast.success(data.text)
            break
          case 'error':
            await Vue.prototype.$toast.error(data.text)
            break
        }
      }
      if (Vue.prototype.$helper.getNestedObj(['refreshData', 'where'], data) !== null) {
        switch (data.refreshData.where) {
          case 'hosting.servers':
          case 'hosting.providers':
          case 'domains.management':
          case 'domains.providers':
          case 'domains.cloudflare':
            Vue.prototype.$refreshData.leftSidebarDomainsCounters()
            if (Object.keys(data.refreshData?.data || {}).length > 1) {
              Vue.prototype.$refreshData.updateRecordInResourceTable(data.refreshData.where, data.refreshData.data)
            } else {
              timeouts[data.refreshData.where] && clearTimeout(timeouts[data.refreshData.where])
              timeouts[data.refreshData.where] = setTimeout(() => {
                Vue.prototype.$refreshData.reloadResourceTable(data.refreshData.where)
              }, 100)
            }
            break
          case 'affiliates':
          case 'tags':
          case 'emails':
            if (Object.keys(data.refreshData.data).length > 1) {
              Vue.prototype.$refreshData.updateRecordInResourceTable(data.refreshData.where, data.refreshData.data)
            } else {
              Vue.prototype.$refreshData.reloadResourceTable(data.refreshData.where)
            }
            break
          case 'tasks.generator':
            if (data.refreshData.data !== undefined) {
              Vue.prototype.$refreshData.generatorTaskRefresh(data.refreshData.data)
            } else {
              Vue.prototype.$refreshData.reloadResourceTable(data.refreshData.where)
            }
            Vue.prototype.$refreshData.generatorTaskRefresh(data.refreshData.data)
            Vue.prototype.$refreshData.leftSidebarDomainsCounters()
            Vue.prototype.$refreshData.reloadResourceTable('domains.management')
            break
          case 'tasks.lpg':
            if (Vue.prototype.$helper.getNestedObj(['refreshData', 'data', 'download'], data) !== null) {
              await helper.download(data.refreshData.data.download)
            }
            break
          case 'tasks.sdr':
            Vue.prototype.$refreshData.reloadResourceTable(data.refreshData.where)
            Vue.prototype.$refreshData.leftSidebarDomainsCounters()
            Vue.prototype.$refreshData.reloadResourceTable('domains.management')
            break
          case 'tasks.unpacking':
            // this.$refreshData.reloadResourceTable(data.refreshData.where)
            Vue.prototype.$refreshData.updateUnpacking(data.refreshData.data)
            break
          case 'eventLog':
            store.commit('eventLog/addEvent', data.refreshData.data)
            break
          case 'resources':
            if (Vue.prototype.$helper.getNestedObj(['refreshData', 'data', 'lockedEntities'], data) !== null) {
              store.commit('lockedEntities/setLockedEntities', data.refreshData.data.lockedEntities)
            }
            if (Vue.prototype.$helper.getNestedObj(['refreshData', 'data', 'lockState'], data) === null) {
              Vue.prototype.$refreshData.updateRecordInResourceTable(data.refreshData.data.resource, data.refreshData.data.entityData)
              Vue.prototype.$refreshData.leftSidebarDomainsCounters()
            }
            Vue.prototype.$refreshData.updateLockedEntities(data.refreshData.data)
            // this.$refreshData.reloadResourceTable('domains.management')
            break
          case 'dashboard.requests':
            // store.commit(types.SET_DASHBOARD_GENERAL_COUNTERS, response.data)
            Vue.prototype.$refreshData.updateDashboardRequestsStats(data.refreshData.data)
            break
          case 'user.auth':
            Vue.prototype.$refreshData.updateUserAuth(data.refreshData.data)
            break
          case 'user.role':
            Vue.prototype.$refreshData.setUsersPermissionsByRole(data.refreshData.data)
            break
          default:
            // TEST
            break
        }
      }
    })
  }
}
