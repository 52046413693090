import Validator from './Validator'

/**
 * Get validator class
 * @returns {Validator}
 */
const getValidator = () => new Validator()

/**
 * Parse rule
 * @param {String|Array|Function} rule
 * @returns {boolean|*}
 */
export const parseRule = (rule) => {
  if (typeof rule === 'function') {
    return rule
  }
  const validator = getValidator()
  let ruleName
  let args = []
  let segments
  if (Array.isArray(rule) && rule.length) {
    rule = rule.map(r => r)
    segments = rule
  } else if (typeof rule === 'string' && rule) {
    segments = rule.split(':')
  }
  if (segments instanceof Array && segments.length > 0) {
    if (segments.length > 1) {
      ruleName = segments.shift()
      args = segments
    } else {
      ruleName = segments[0]
    }
  }
  if (ruleName !== undefined && typeof validator[ruleName] === 'function') {
    return validator[ruleName](...args)
  }
  return false
}

/**
 * Parse Rules
 * @param {String|Array} validation
 * @returns {*|[]|*[]}
 */
export const parseRules = (validation) => {
  if (typeof validation === 'string' && validation) {
    return parseRules(validation.split('|'))
  }
  if (!Array.isArray(validation)) {
    return []
  }
  return validation.map(rule => parseRule(rule)).filter(f => !!f)
}

export default {
  parseRules
}
