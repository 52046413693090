import Vue from 'vue'
import permissionsService from '@/services/api/users/permissions.service'

const getDefaultState = (def = {}) => ({
  ...{
    mainRole: process.env.VUE_APP_MAIN_ROLE || '',
    rolesList: [],
    permissionsList: {}
  },
  ...def
})

export default {
  namespaced: true,
  name: 'usersPermissions',
  state: getDefaultState(),
  getters: {
    mainRole: state => state.mainRole,
    rolesList: state => state.rolesList,
    permissionsList: state => state.permissionsList
  },
  actions: {
    async getRolesList (context) {
      const response = await permissionsService.getRolesList()
      if (response.status === 200) {
        // return response
        const data = response.data.roles
        context.commit('setRolesList', data)
      }
    },
    async getPermissionsList (context, data) {
      const response = await permissionsService.getPermissionsList((data || {}).role)
      if (response.status === 200) {
        // return response
        const data = response.data
        context.commit('setPermissionsList', data)
      }
    },
    async getPermissionsForRole (context, role) {
      const response = await permissionsService.getPermissionsForRole(role)
      if (response.status === 200) {
        return response.data.permissions
      }
      return []
    },
    async savePermission (context, data) {
      const response = await permissionsService.savePermissions(data)
      if (response.status === 200 || response.status === 201) {
        context.commit('updatePermissionValueData', response.data)
        return true
      }
    }
  },
  mutations: {
    setRolesList (state, data) {
      state.rolesList = data
    },
    setPermissionsList (state, data) {
      state.permissionsList = data
    },
    updatePermissionValueData (state, data) {
      if (!{}.hasOwnProperty.call(state.permissionsList.values, data.role)) {
        Vue.set(state.permissionsList.values, data.role, { [data.resource]: { [data.action]: data.value } })
      } else if (!{}.hasOwnProperty.call(state.permissionsList.values[data.role], data.resource)) {
        Vue.set(state.permissionsList.values[data.role], data.resource, { [data.action]: data.value })
      } else {
        Vue.set(state.permissionsList.values[data.role][data.resource], data.action, data.value)
      }
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
