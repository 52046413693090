<template>
  <code
    v-if="!isEnded"
    :style="`${ !!width ? `width: ${width}px` : ''}`"
  >{{ days > 0 ? fillZero(days) : '' }} {{ fillZero(hours) }}:{{ fillZero(minutes) }}:{{ fillZero(seconds) }} {{ label !== '' ? label : '' }}</code>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    endDate: {
      type: Date,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      timer: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      isEnded: null
    }
  },
  watch: {
    endDate(newVal) {
      this.isEnded = null
      this.tick()
      this.timer = setInterval(this.tick.bind(this), 1000)
    }
  },
  mounted () {
    this.tick()
    this.timer = setInterval(this.tick.bind(this), 1000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    updateRemaining (distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
    },
    tick () {
      const currentTime = new Date()
      const distance = Math.max(this.endDate - currentTime, 0)
      this.updateRemaining(distance)

      if (distance === 0) {
        clearInterval(this.timer)
        this.isEnded = true
      }
    },
    fillZero(num) {
      return num < 10 ? '0' + num : num
    },
    fillMillisecond(n) {
      return ('0000' + n).slice(-4)
    }
  }
}
</script>

<style scoped>

</style>
