import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Get "ALL" Hosting Servers (limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getHostingServersList = async () => {
  return axios.get(paths.hosting.servers.all)
}

/**
 * Get "ALL with Vesta" Hosting Servers (limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getVestaHostingServersList = async () => {
  return axios.get(paths.hosting.servers.allWithVesta)
}

/**
 * Get "ALL" Hosting Servers by provider id (limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getHostingServersListByProviderId = async (id) => {
  return axios.get(paths.hosting.servers.allByProviderId.replace(':provider_id', id))
}

/**
 * Get Hosting Server Data
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getHostingServerData = async (id) => {
  return axios.get(paths.hosting.servers.view.replace(':id', id))
}

/**
 * Get Hosting Server Credentials
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getHostingServerCredentials = async (id) => {
  return axios.post(paths.hosting.servers.auth.replace(':id', id))
}

/**
 * Get Hosting Server IPs
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.getHostingServerIp = async (id) => {
  return axios.get(paths.hosting.servers.api.ips.replace(':id', id))
}

/**
 * Get Hosting Server Users
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.getHostingServerUsers = async (id) => {
  return axios.get(paths.hosting.servers.api.users.replace(':id', id))
}

/**
 * Import Domains From Server
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.importDomains = async (id) => {
  return axios.get(paths.hosting.servers.api.import.replace(':id', id))
}

/**
 * Sync Domain From Server
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.domainSync = async (id, name) => {
  return axios.post(paths.hosting.servers.api.sync.replace(':id', id), { name })
}

/**
 * Add Domain
 * @param {String} id
 * @param {String} domain
 * @param {String} ip
 * @param {String} user
 * @return {AxiosPromise<any>}
 */
methods.addDomain = async (id, domain, ip, user) => {
  return axios.post(paths.hosting.servers.api.domains.replace(':id', id), { domain, ip, user })
}

/**
 * Update Domain
 * @param {String} id
 * @param {String} domain
 * @param {String} ip
 * @param {String} user
 * @return {AxiosPromise<any>}
 */
methods.updateDomain = async (id, domain, ip, user) => {
  return axios.patch(paths.hosting.servers.api.domains.replace(':id', id), { domain, ip, user })
}

/**
 * Update Domain
 * @param {String} id
 * @param {String} domain
 * @param {String} ip
 * @param {String} user
 * @return {AxiosPromise<any>}
 */
methods.removeDomain = async (id, domain, user) => {
  return axios.delete(paths.hosting.servers.api.domains.replace(':id', id), { data: { domain, user } })
}

/**
 * Get Server Cpu Status
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.getServerCpuStatus = async (id) => {
  return axios.get((paths.hosting.servers.api.cpuStatus.replace(':id', id)))
}

/**
 * Get Server Memory Status
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.getServerMemoryStatus = async (id) => {
  return axios.get((paths.hosting.servers.api.memoryStatus.replace(':id', id)))
}

/**
 * Get Server Disk Status
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.getServerDiskStatus = async (id) => {
  return axios.get((paths.hosting.servers.api.diskStatus.replace(':id', id)))
}

/**
 * Change Domain Owner
 * @param {String} id
 * @param {String} domain
 * @param {String} user
 * @return {AxiosPromise<any>}
 */
methods.changeDomainOwner = async (id, domain, user) => {
  return axios.patch((paths.hosting.servers.api.changeDomainOwner.replace(':id', id)), { domain, user })
}

/**
 * Change Domain Owner
 * @param {String} id
 * @param {String} domain
 * @param {String} user
 * @return {AxiosPromise<any>}
 */
methods.domainTransfer = async (data) => {
  const { id, domain, futureServerId, futureServerUser, futureServerIP, withContent } = data
  return axios.post((paths.hosting.servers.api.domainTransfer.replace(':id', id)), { domain, futureServerId, futureServerUser, futureServerIP, withContent })
}

/**
 * Restart Web Services
 * @param {String} id
 * @return {AxiosPromise<any>}
 */
methods.restartWeb = async (id) => {
  return axios.post((paths.hosting.servers.api.restartWeb.replace(':id', id)), {})
}

export default methods
