// import Vue from 'vue'
// import store from '@/store'
import tasksSDRService from '@/services/api/tasks/sdr.service.js'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'tasksSDR',
  state: getDefaultState(),
  getters: {

  },
  actions: {
    async addTask({ dispatch }, inputData) {
      const response = await tasksSDRService.addTask(inputData)
      const { status, data } = response
      if (status === 201) {
        return data
      }
      return response
    },
    /**
     * Restart job in task
     * @param dispatch
     * @param id
     * @param inputData
     * @returns {Promise<*>}
     */
    async controlTask({ dispatch }, { id, inputData }) {
      const response = await tasksSDRService.controlTask(id, inputData)
      const { status, data } = response
      if (status === 200) {
        return data
      }
      return response
    },
    async updateTask({ dispatch }, { id, inputData }) {
      const response = await tasksSDRService.updateTask(id, inputData)
      const { status, data } = response
      if (status === 200) {
        return data
      }
      return response
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
