<template>
  <div>
    <v-list class="logo-text-block">
      <v-list-item @click="gotoHomepage">
        <v-list-item-content>
          <v-list-item-title>
            <span
              role="text"
              class="title"
            >
              Affiliate <span class="blinking-cursor">|</span><span style="color: #949da7;">Buddy <sup
                class="grey--text"
                style="font-size: 9px"
              >{Beta2.0}</sup></span>
              <!--Web<span class="blinking-cursor">|</span><span class="title-opacity-part">Tools</span>-->
            </span>
          </v-list-item-title>
          <v-list-item-subtitle>
            Domains &middot; Hosting &middot; DNS
            <!-- This is our everything... -->
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list>
      <div
        v-show="!!loadLeftSidebarProgress"
        class="pa-5"
      >
        <v-progress-linear
          :active="loadLeftSidebarProgress"
          color="primary"
          indeterminate
          rounded
          height="6"
        />
      </div>
      <v-spacer />
      <navigation-left-sidebar-item
        v-for="(item,index) in items"
        :key="index"
        :item="item"
      />
    </v-list>
    <v-divider />
    <v-list>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title>
            <span class="text-h6">{{ $currentUser.name }}</span> <sup class="grey--text small">{{ $currentUser.global ? `global:` : '' }}{{ $currentUser.role }}</sup>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="$currentUser.jobTitle"
            style="color: #73c028"
            class="mt-1 font-weight-lighter"
          >
            {{ $currentUser.jobTitle ? `${$currentUser.jobTitle}` : '' }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="mt-1"
          >
            {{ $currentUser.team ? `${$currentUser.team}  &middot; ` : '' }} {{ $currentUser.email }}
          </v-list-item-subtitle>
          <div />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import leftSidebar from './_leftSidebar.js'

export default {
  name: 'LeftSidebar',
  data() {
    return {
      loadLeftSidebarProgress: true,
      leftSidebar: [],
      delayTimer: null
    }
  },
  computed: {
    items() {
      return this.leftSidebar
    },
    getLocale() {
      return this.$store.getters['locale/locale']
    }
  },
  watch: {
    /**
     * Reloading Left Sidebar after change language
     * @param {String} val
     * @returns {Promise<void>}
     */
    async getLocale(val) {
      await this.loadLeftSidebar()
    }
  },
  async mounted () {
    await this.loadLeftSidebar()
    this.$eventBus.$on('leftSidebarReloadDomainsCounters', () => {
      this.loadLeftSidebar()
    })
  },
  methods: {
    async loadLeftSidebar() {
      try {
        this.reloadDomainsCountsByStatuses()
      } catch (e) {
        console.log(e)
      } finally {
        // this.loadLeftSidebarProgress = false
      }
    },
    reloadDomainsCountsByStatuses() {
      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(async () => {
        await this.$store.dispatch('domains/getDomainsCountsByStatuses')
        this.leftSidebar = leftSidebar(this.$i18n, this.$store.getters['domains/counts'])
        this.loadLeftSidebarProgress = false
      }, 400)
    },
    async gotoHomepage () {
      if (this.$route.name !== 'dashboard') {
        await this.$router.push({ name: 'dashboard' }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss">
.logo-text-block {
  margin-top: -3px;
  padding: 0;
  .v-list-item__content {
    padding: 7px 0;
  }
  .title {
    color: #24292f !important;
  }
  .title-opacity-part {
    color: #949da7;
  }
}
</style>
