const { localStorage } = global
const sessionStorage = localStorage // sessionStorage is not needed in this project

const STORAGE_MODE_SESSION_KEY = 'persistMode'

class Storage {
  /**
   * Storage constructor
   */
  constructor() {
    this.shouldPersist = true // sessionStorage is not needed in this project
    // this.shouldPersist = localStorage.getItem(STORAGE_MODE_SESSION_KEY) === 'true'
  }

  /**
   * Set should persist
   * @param persist
   */
  setShouldPersist(persist) {
    this.shouldPersist = !!persist
    if (persist) {
      localStorage.setItem(STORAGE_MODE_SESSION_KEY, 'true')
    } else {
      localStorage.removeItem(STORAGE_MODE_SESSION_KEY)
    }
  }

  /**
   * Get should persist
   * @returns {boolean}
   */
  getShouldPersist() {
    return this.shouldPersist
  }

  /**
   * Get item from storage
   * @param {String} name
   * @returns {*}
   */
  getItem(name) {
    return this.shouldPersist ? localStorage.getItem(name) : sessionStorage.getItem(name)
  }

  /**
   * Add item to storage
   * @param {String} name
   * @param {String|Boolean} value
   * @returns {*}
   */
  setItem(name, value) {
    return this.shouldPersist ? localStorage.setItem(name, value) : sessionStorage.setItem(name, value)
  }

  /**
   * Remove item from storage
   * @param {string[]} name
   * @returns {*[]}
   */
  removeItem(name) {
    const nameArray = Array.isArray(name) ? name : [name]
    return nameArray.map(n => (this.shouldPersist ? localStorage.removeItem(n) : sessionStorage.removeItem(n)))
  }

  /**
   * Clear all storages
   */
  clearAllItems() {
    localStorage.clear()
    sessionStorage.clear()
  }
}

export default new Storage()
