import Vue from 'vue'
import helper from '../utils/helper'

const plugin = {
  install () {
    Vue.helpers = helper
    Vue.prototype.$helper = helper
  }
}

Vue.use(plugin)
