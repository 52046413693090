import affiliatesService from '@/services/api/affiliates/affiliates.service'

const getDefaultState = (def = {}) => ({
  ...{},
  ...def
})

export default {
  namespaced: true,
  name: 'affiliates',
  state: getDefaultState(),
  getters: () => {
  },
  actions: {
    async sync({ dispatch }) {
      try {
        const response = await affiliatesService.import()
        if (response.status === 200) {
          await dispatch('__affiliates.management/list', null, { root: true })
          //
        }
      } catch (e) {
        throw new Error(e.response.data.message)
        // e
      }
    },
    async all({ dispatch }, forSelect = true) {
      let data = []
      try {
        const response = await affiliatesService.all()
        if (response.status === 200) {
          if (forSelect === true) {
            Object.keys(response.data).forEach((key) => {
              const affiliate = response.data[key]
              data.push({ text: `${affiliate.name} | ${affiliate.team}`, value: affiliate.id })
            })
          } else {
            data = response.data
          }
        }
      } catch (e) {
        // e
      }
      return data
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
