<template>
  <v-stepper
    v-model="step"
    class="ma-2"
  >
    <v-stepper-header>
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        {{ $t('2FA Status') }}
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        {{ get2FAStatus === false ? $t('Confirm enabling') : $t('Confirm disabling') }}
      </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3">
        Completion
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="mb-12"
          elevation="0"
        >
          <div v-if="get2FAStatus === false">
            <div class="text-h6">
              Your account is not secure!
            </div>
            <div class="mb-2 mt-3">
              We highly recommend enabling two-factor authentication (2FA)
            </div>
            <div class="">
              2FA increases the security of your account. Even if somebody guesses your password, they won’t be able to
              access your account.
            </div>
          </div>
          <div v-if="get2FAStatus === true">
            <div class="text-h6">
              Protection is on, everything is alright!
            </div>
          </div>
        </v-card>

        <v-btn
          :color="get2FAStatus === true ? 'lightslategrey' : 'primary'"
          class="mr-3"
          :disabled="loading"
          @click="get2FAStatus === true ? step2disable() : step2enable()"
        >
          {{ get2FAStatus === true ? 'Disable 2FA' : 'Enable 2FA' }}
        </v-btn>

        <v-btn
          text
          :disabled="loading"
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
          class="mb-12 text-center"
          color="lighten-1"
          elevation="0"
        >
          <div v-if="get2FAStatus === false">
            <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
            >
              You need to download "Google Authenticator" application on your mobile device and scan this QR-code
            </v-alert>
            <img
              :src="qr"
              alt="QR-Code"
            >
            <div
              class="ma-auto position-relative"
              style="max-width: 300px"
            >
              <v-otp-input
                ref="otp"
                v-model="token"
                :disabled="loading"
                @finish="step3enable"
              />
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </v-overlay>
            </div>
          </div>
          <div v-if="get2FAStatus === true">
            <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
            >
              Enter token to disable two factor authentication
            </v-alert>
            <div
              class="ma-auto position-relative"
              style="max-width: 300px"
            >
              <v-otp-input
                ref="otp"
                v-model="token"
                :disabled="loading"
                @finish="step3disable"
              />
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </v-overlay>
            </div>
          </div>
        </v-card>

        <!--v-btn
          color="primary"
          class="mr-3"
          :disabled="loading"
          @click="step3"
        >
          Continue
        </v-btn-->

        <v-btn
          text
          :disabled="loading"
          @click="step1"
        >
          Prev
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          class="mb-12"
          elevation="0"
        >
          <div v-if="get2FAStatus === true">
            <div class="text-h6">
              Congratulations!
            </div>
            <div class="mb-2 mt-3">
              You have successfully activated additional protection for your account!
            </div>
            <div class="mb-2 mt-3">
              Now your data is safe...
            </div>
          </div>
          <div v-if="get2FAStatus === false">
            <div class="text-h6">
              Note!
            </div>
            <div class="mb-2 mt-3">
              Now your account is not protected by two-factor authentication
            </div>
          </div>
        </v-card>

        <v-btn
          color="primary"
          class="mr-3"
          @click="$emit('close')"
        >
          Finish
        </v-btn>

        <v-btn
          v-if="get2FAStatus === false"
          text
          :disabled="loading"
          @click="step2enable"
        >
          Re-enable 2FA
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  name: 'Settings2FA',
  data () {
    return {
      loading: false,
      step: 1,
      qr: '',
      secret: '',
      response: {},
      token: null,
      validated: false
    }
  },
  computed: {
    getResponse () {
      return this.response
    },
    get2FAStatus() {
      return this.$store.getters['auth/loggedInUser'].twoFactorEnabled
    }
  },
  mounted () {

  },
  methods: {
    async step1 () {
      this.step = 1
    },
    async step2enable () {
      this.token = ''
      this.step = 2
      this.loading = true
      this.response = await this.$store.dispatch('users/enable2FAStep1')
      const { qr, secret } = this.response
      this.qr = qr
      this.secret = secret
      this.loading = false

      setTimeout(() => {
        this.$refs.otp.focus()
      }, 100)
    },
    async step2disable () {
      this.token = ''
      this.step = 2
      this.loading = true
      this.loading = false

      setTimeout(() => {
        this.$refs.otp.focus()
      }, 100)
    },
    async step3enable () {
      if (!this.token || !this.secret?.base32) {
        await this.$toast.error(this.$t('Missing required data (token or secret base32)'))
        return this.step2enable()
      }
      this.loading = true
      try {
        this.response = await this.$store.dispatch('users/enable2FAStep2', {
          token: this.token,
          base32: this.secret.base32
        })
        const { validated } = this.response
        this.validated = validated
        if (validated === true) {
          this.step = 3
        }
      } catch (e) {
        await this.$toast.error(e.response.data.message)
        await this.step2enable()
      }
      this.loading = false
    },
    async step3disable () {
      if (!this.token) {
        await this.$toast.error(this.$t('Missing required data (token)'))
        return this.step2disable()
      }
      this.loading = true
      try {
        this.response = await this.$store.dispatch('users/disable2FA', {
          token: this.token
        })
        if (this.response.status === 204) {
          this.step = 3
        }
      } catch (e) {
        await this.$toast.error(e.response.data.message)
        await this.step2disable()
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
