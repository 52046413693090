import Storage from '@/libs/storage/Storage'
import authService from '@/services/api/auth.service'

const userDataJson = Storage.getItem('user') || null
const userDataObj = userDataJson ? JSON.parse(userDataJson) : {}

const getDefaultState = (def = {}) => ({
  ...{
    refreshToken: Storage.getItem('refreshToken') || '',
    accessToken: Storage.getItem('accessToken') || '',
    loggedInUser: userDataObj,
    isAuthenticated: Object.keys(userDataObj).length > 0
  },
  ...def
})

const getters = {
  refreshToken: state => state.refreshToken,
  accessToken: state => state.accessToken,
  loggedInUser: state => state.loggedInUser,
  isAuthenticated: state => Boolean(state.isAuthenticated)
}

const actions = {
  /**
   * Login action
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} payload
   * @returns {Promise<void>}
   */
  async login({ commit, dispatch }, payload) {
    await commit('CLEAR_USER_DATA')
    const rememberMe = payload.rememberMe
    const step = payload.step
    delete payload.rememberMe
    const response = await authService.login(payload)
    if (response.status === 200) {
      const { twoFactorEnabled } = response.data
      if (twoFactorEnabled === true && step === 1) {
        return response.data
      }
      Storage.setShouldPersist(rememberMe)
      const accessToken = response.data.tokens.access.token
      const refreshToken = response.data.tokens.refresh.token
      await commit('SET_ACCESS_TOKEN', accessToken)
      await commit('SET_REFRESH_TOKEN', refreshToken)
      await dispatch('fetchUser')
    }
    return response
  },
  async loginWithOneTimeToken({ commit, dispatch }, payload) {
    await commit('CLEAR_USER_DATA')
    const response = await authService.loginWithOneTimeToken(payload)
    if (response.status === 200) {
      const accessToken = response.data.tokens.access.token
      const refreshToken = response.data.tokens.refresh.token
      await commit('SET_ACCESS_TOKEN', accessToken)
      await commit('SET_REFRESH_TOKEN', refreshToken)
      await dispatch('fetchUser')
    }
    return response
  },
  /**
   * Fetch user action
   * @param {Function} commit
   * @returns {Promise<void>}
   */
  async fetchUser({ commit }) {
    const response = await authService.getCurrentUser()
    if (response.status === 200) {
      await commit('SET_LOGGED_IN_USER', response.data)
    }
  },
  /**
   * Refresh token action
   * @param {Object} state
   * @param {Function} commit
   * @returns {Promise<void>}
   */
  async refreshToken({ state, commit }) {
    const response = await authService.refreshTokens(state)
    if (response.status === 200) {
      const accessToken = response.data.access.token
      const refreshToken = response.data.refresh.token
      await commit('SET_ACCESS_TOKEN', accessToken)
      await commit('SET_REFRESH_TOKEN', refreshToken)
    }
  },
  /**
   * Refresh token action
   * @param {Object} state
   * @param {Function} commit
   * @returns {Promise<void>}
   */
  async refreshAccessToken({ state, commit }) {
    const response = await authService.refreshAccessToken(state)
    if (response.status === 200) {
      const accessToken = response.data.access.token
      const refreshToken = response.data.refresh.token
      await commit('SET_ACCESS_TOKEN', accessToken)
      await commit('SET_REFRESH_TOKEN', refreshToken)
    }
  },
  /**
   * Logout action
   * @param state
   * @param commit
   * @returns {Promise<void>}
   */
  async logout({ state, commit }) {
    return new Promise((resolve, reject) => {
      try {
        authService.logout(state)
        commit('CLEAR_USER_DATA')
        resolve()
      } catch (e) {
        reject(e.message)
      }
    })
  }
}

const mutations = {
  /**
   * Mutation for setting refresh token
   * @param {Object} state
   * @param {String} refreshToken
   */
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken
    Storage.setItem('refreshToken', refreshToken)
  },
  /**
   * Mutation for setting access token
   * @param {Object} state
   * @param {String} accessToken
   */
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken
    Storage.setItem('accessToken', accessToken)
  },
  /**
   * Mutation for setting user data after auth
   * @param {Object} state
   * @param {Object} user
   */
  SET_LOGGED_IN_USER(state, user) {
    state.loggedInUser = user
    state.isAuthenticated = true
    Storage.setItem('user', JSON.stringify(user))
    Storage.setItem('isAuthenticated', true)
  },
  /**
   * Mutation for clear all user data
   * @param {String} state
   */
  CLEAR_USER_DATA(state) {
    state.refreshToken = null
    state.accessToken = null
    state.loggedInUser = { roleRights: [] }
    state.isAuthenticated = false
    Storage.removeItem(['refreshToken', 'accessToken', 'user', 'isAuthenticated', 'socketSid', 'socketUid'])
    this.dispatch('resetStates')
  },
  SET_PERMISSIONS_BY_ROLE(state, data) {
    if (state.loggedInUser.role !== data.role) {
      return false
    }
    Object.assign(state.loggedInUser, { roleRights: data.permissions })
    Storage.setItem('user', JSON.stringify(state.loggedInUser))
    this.commit('app/INCR_HOT_UPDATE')
  },
  /**
   * Reset state
   * @param state
   */
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  name: 'auth',
  state: getDefaultState(),
  actions,
  mutations,
  getters
}
