export default {
  name: 'MixinsResizer',
  data () {
    return {
      pageHeight: 0,
      mobile: false
    }
  },
  computed: {
    $pageHeight () {
      return this.pageHeight
    },
    $isMobile () {
      return this.mobile
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    /* Removing the window.resize event listener */
    if (typeof window === 'undefined') {
      return false
    }
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      this.pageHeight = window.innerHeight - (window.innerWidth < 901 ? 204 : 240)
      this.mobile = window.innerWidth <= 600
    }
  }
}
