<template>
  <v-app>
    <dialog-container />
    <toasts-container />
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  }
}
</script>

<style lang="scss">
@import '../node_modules/@fontsource/roboto/100.css';
@import '../node_modules/@fontsource/roboto/300.css';
@import '../node_modules/@fontsource/roboto/400.css';
@import '../node_modules/@fontsource/roboto/500.css';
@import '../node_modules/@fontsource/roboto/700.css';
@import '../node_modules/@fontsource/roboto/900.css';

.small {
  font-size: 12px;
}
.strike {
  text-decoration: line-through;
}
.v-messages__message {
  color: #cacaca;
}
</style>
