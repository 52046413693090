import axios from 'axios'
import paths from '../../../paths'

const methods = {}

/**
 * Get "All" Cloudflare Accounts (limit 100)
 * @returns {AxiosPromise<any>}
 */
methods.getAllCloudflareAccounts = async () => {
  return axios.get(paths.domains.cloudflare.all)
}

/**
 * Get Cloudflare Account Credentials
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getCloudflareCredentials = async (id) => {
  return axios.post(paths.domains.cloudflare.auth.replace(':id', id))
}

/**
 * Get Account NSS
 * @param {String} id
 * @returns {AxiosPromise<any>}
 */
methods.getAccountNSS = async (id) => {
  return axios.get(paths.domains.cloudflare.nss.replace(':id', id))
}

/**
 * Get Zones List By (API)
 * @param {String} id (Cloudflare Account ID)
 * @returns {AxiosPromise<any>}
 */
methods.getZonesList = async (id) => {
  return axios.get(paths.domains.cloudflare.api.zones.replace(':id', id))
}

/**
 * Add Zone (API)
 * @param {String} id (Cloudflare Account ID)
 * @param name
 * @param ip
 * @param ssl
 * @returns {AxiosPromise<any>}
 */
methods.zoneAdd = async (id, name, ip, ssl) => {
  return axios.post(paths.domains.cloudflare.api.zones.create.replace(':id', id), { name, ip, ssl })
}

/**
 * Import Domains From CF Acc
 * @param {String} id (Cloudflare Account ID)
 * @returns {AxiosPromise<any>}
 */
methods.importDomains = async (id) => {
  return axios.get(paths.domains.cloudflare.api.zones.import.replace(':id', id))
}

/**
 * Bulk Import Domains From CF Accounts
 * @param {Array} id (Cloudflare Account ID)
 * @returns {AxiosPromise<any>}
 */
methods.bulkImportDomains = async (ids) => {
  return axios.post(paths.domains.cloudflare.api.zones.bulkImport, { ids })
}

/**
 * Zone Status (Deprecated)
 * @param {String} id (Cloudflare Account ID)
 * @param {String} zoneId
 * @returns {AxiosPromise<any>}
 */
methods.zoneStatus = async (id, zoneId) => {
  return axios.patch(paths.domains.cloudflare.api.zones.status.replace(':id', id), { zone_id: zoneId })
}

/**
 * Zone sync
 * @param {String} id (Cloudflare Account ID)
 * @param zoneId
 * @param name
 * @returns {AxiosPromise<any>}
 */
methods.zoneSync = async (id, zoneId, name) => {
  return axios.patch(paths.domains.cloudflare.api.zones.sync.replace(':id', id), { zone_id: zoneId, name })
}

/**
 * Zone Save
 * @param {Object} data
 * @return {AxiosPromise<any>}
 */
methods.zoneSave = async (data) => {
  if (data.zoneId) {
    return axios.patch(
      paths.domains.cloudflare.api.zones.update.replace(':id', data.id),
      {
        name: data.name,
        ip: data.ip,
        ssl: data.ssl,
        devMode: data.devMode,
        autoHttpsRewrite: data.autoHttpsRewrite,
        alwUseHttps: data.alwUseHttps
      }
    )
  } else {
    return axios.post(paths.domains.cloudflare.api.zones.create.replace(':id', data.id),
      {
        name: data.name,
        ip: data.ip,
        ssl: data.ssl,
        devMode: data.devMode,
        autoHttpsRewrite: data.autoHttpsRewrite,
        alwUseHttps: data.alwUseHttps
      }
    )
  }
}

/**
 * Zone Remove
 * @param {String} id
 * @param {String} zoneId
 * @param {String} name
 * @returns {AxiosPromise<any>}
 */
methods.zoneRemove = async (id, zoneId, name) => {
  return axios.delete(
    paths.domains.cloudflare.api.zones.remove
      .replace(':id', id)
      .replace(':zone_id', zoneId),
    {
      data: {
        zone_id: zoneId,
        name
      }
    }
  )
}

/**
 * Purge Zone Cache
 * @param {String} id
 * @param {String} zoneId
 * @return {AxiosPromise<any>}
 */
methods.purgeCache = async (id, zoneId) => {
  return axios.post(
    paths.domains.cloudflare.api.zones.purgeCache
      .replace(':id', id)
      .replace(':zone_id', zoneId)
  )
}

/**
 * Purge Zone Cache
 * @param {String} id
 * @param {String} zoneId
 * @param {String} value
 * @return {AxiosPromise<any>}
 */
methods.setDevMode = async (id, zoneId, value) => {
  return axios.post(
    paths.domains.cloudflare.api.zones.devMode
      .replace(':id', id)
      .replace(':zone_id', zoneId),
    {
      value
    }
  )
}

/**
 * Zone DNS Save
 * @param {Object} data
 * @return {AxiosPromise<any>}
 */
methods.dnsSave = async(data) => {
  if (data.dnsZoneId !== null) {
    return axios.put(
      paths.domains.cloudflare.api.dns.update
        .replace(':id', data.id)
        .replace(':zone_id', data.zoneId)
        .replace(':dns_zone_id', data.dnsZoneId),
      {
        type: data.type,
        name: data.name,
        content: data.content,
        proxied: data.proxied
      }
    )
  } else {
    return axios.post(
      paths.domains.cloudflare.api.dns.create
        .replace(':id', data.id)
        .replace(':zone_id', data.zoneId),
      {
        type: data.type,
        name: data.name,
        content: data.content,
        proxied: data.proxied
      }
    )
  }
}

/**
 * Remove DNS Record for Zone (API)
 * @param {String} id (Cloudflare Account ID)
 * @param zoneId
 * @param dnsZoneId
 * @returns {AxiosPromise<any>}
 */
methods.dnsRemove = async (id, zoneId, dnsZoneId) => {
  return axios.delete(
    paths.domains.cloudflare.api.dns.remove
      .replace(':id', id)
      .replace(':zone_id', zoneId)
      .replace(':dns_zone_id', dnsZoneId)
  )
}

export default methods
